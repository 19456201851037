import { Button, FormControl, FormLabel, Heading, HStack, Input, VStack } from '@chakra-ui/react'
import Result from '@defs/Result'
import { ImageField } from '@services/getS3SignedUrl.service'
import uploadImage from '@services/uploadImage.service'
import generateProviderImageKey from '@utils/generateProviderImageKey'
import ImageUploadBox from '@v1_atoms/ImageUploadBox/ImageUploadBox.component'
import EventQueryingPage, { EventWithImages } from '@v1_hocs/EventQueryingPage.component'
import ImageUploadFormSection from '@v1_molecules/ImageUploadFormSection/ImageUploadFormSection.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function AddEventImagesContents({ data }: { data: EventWithImages }) {
    const navigate = useNavigate()

    const { id: eventId, providers } = data

    const [imageAuthToken, setImageAuthToken] = useState(
        process.env.REACT_APP_IMAGE_API_POST_AUTH ?? '',
    )

    const [imagesUploading, setImagesUploading] = useState(false)

    async function handleImageUpload(evt: FormEvent<HTMLFormElement>) {
        evt.preventDefault()
        setImagesUploading(true)

        const e = evt as unknown as { target: HTMLInputElement[] }

        const images: { [s in ImageField]: HTMLInputElement | undefined } = {
            primary: e.target[2],
            'confirm/big': e.target[4],
            'confirm/medium': e.target[6],
            'confirm/small': e.target[8],
            'thank-you': e.target[10],
            organiser: e.target[12],
        }

        providers.forEach(({ name }, idx) => {
            images[`providers/${generateProviderImageKey({ name, idx })}`] = e.target[14 + idx * 2]
        })

        const promises: Promise<Result<void>>[] = Object.entries(images)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_key, value]) => value)
            .map(([key, image]) => {
                return uploadImage(
                    eventId!,
                    key as ImageField,
                    image!.files![0],
                    imageAuthToken,
                    () => undefined,
                )
            })

        const results = await Promise.all(promises)

        setImagesUploading(false)

        if (results.every(({ success }) => success)) {
            navigate(`/events/${eventId}`)
            return
        }

        // eslint-disable-next-line no-alert
        alert('something went wrong uploading the images. Try again')
    }

    return (
        <>
            <VStack>
                <NavHeading menuColour="green" headingText="Turbafy" />
                <form onSubmit={handleImageUpload}>
                    <VStack spacing={8} maxW="800px" textColor="plejGreen.500" alignItems="start">
                        <Heading as="h2">Add images for your event</Heading>
                        <FormControl>
                            <FormLabel>Image API auth token:</FormLabel>
                            <Input
                                type="text"
                                value={imageAuthToken}
                                onChange={(e) => setImageAuthToken(e.target.value)}
                            />
                        </FormControl>
                        <ImageUploadFormSection
                            sectionName="primary"
                            description="This image will be the image used as the thumbnail for you event on the events page and also be the background image people see when clicking into your event. Make sure its a good size to remain crisp on larger screen sizes."
                        >
                            <ImageUploadBox />
                        </ImageUploadFormSection>
                        <ImageUploadFormSection
                            sectionName="confirm and pay"
                            description="These images will be shown on the page where the customer selects which of the ticket options they wish to attend using"
                        >
                            <HStack w="full">
                                <ImageUploadBox />
                                <ImageUploadBox />
                                <ImageUploadBox />
                            </HStack>
                        </ImageUploadFormSection>
                        <ImageUploadFormSection
                            sectionName="thank you"
                            description="This image will be the image shown when a customer has pledged to attend your event."
                        >
                            <ImageUploadBox />
                        </ImageUploadFormSection>
                        <ImageUploadFormSection
                            sectionName="organiser"
                            description="This image will be the image shown as the avatar image for the organiser of this event."
                        >
                            <ImageUploadBox />
                        </ImageUploadFormSection>
                        <ImageUploadFormSection
                            sectionName="providers"
                            description="This image will be the image shown as the avatar image for the organiser of this event."
                        >
                            {providers.map(({ name }) => (
                                <VStack w="full" mt={4} spacing={4} alignItems="start">
                                    <Heading
                                        as="h4"
                                        fontSize="xl"
                                        mb={2}
                                        textTransform="capitalize"
                                    >
                                        {name}
                                    </Heading>
                                    <ImageUploadBox />
                                </VStack>
                            ))}
                        </ImageUploadFormSection>
                    </VStack>
                    <Button type="submit" isLoading={imagesUploading} my={8}>
                        Add images
                    </Button>
                </form>
            </VStack>

            {/* <HStack>
                <FormControl>
                    <form onSubmit={handleImageUpload}>
                        <Input type="file" accept="image/*" onChange={handleImageUploadChange} />
                        <Button type="submit">Upload</Button>
                    </form>
                </FormControl>
                <Image src={fileLink} fallback={<Box w="full" h="full" bg="plejGreen.500" />} />
            </HStack> */}
        </>
    )
}

export default function AddEventImages() {
    return (
        <EventQueryingPage>
            {(data: EventWithImages) => <AddEventImagesContents data={data} />}
        </EventQueryingPage>
    )
}
