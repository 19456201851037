import { Box, Image } from '@chakra-ui/react'

function SuccessImage({ src, alt }: { src?: string; alt?: string }) {
    return (
        <Image
            mt="56px"
            alignSelf="center"
            boxSize="calc(100% - 112px)"
            objectFit="cover"
            borderRadius="24px"
            src={src}
            alt={alt}
            fallback={<Box w="full" h="full" bg="plejGreen.500" />}
        />
    )
}

export default SuccessImage
