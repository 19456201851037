import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import {
    CreateEventInputPropsNumber,
    CreateEventInputPropsSelect,
    CreateEventInputTyping,
} from '@v1_atoms/CreateEventInput/CreateEventInput.component'
import RequiredCreateEventInputField from '@v1_atoms/RequiredCreateEventInputField/RequiredCreateEventInputField.component'
import { CreateEventSectionProps } from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import { useState } from 'react'

interface BaseRequiredCreateEventInputProps {
    onChange: (v: string) => void
    onValidationChange: (isValid: boolean) => void
    label: string
    errorMessage: string
    parentFormVariant: CreateEventSectionProps['variant']
    initialValue?: string
}

interface RequiredCreateEventInputTypingProps extends BaseRequiredCreateEventInputProps {
    type: CreateEventInputTyping['type']
}

export interface RequiredCreateEventInputNumberProps extends BaseRequiredCreateEventInputProps {
    type: CreateEventInputPropsNumber['type']
    precision?: CreateEventInputPropsNumber['precision']
}

interface RequiredCreateEventInputSelectProps extends BaseRequiredCreateEventInputProps {
    type: CreateEventInputPropsSelect['type']
    options: CreateEventInputPropsSelect['options']
    placeholder: string
}

export type RequiredCreateEventInputProps =
    | RequiredCreateEventInputTypingProps
    | RequiredCreateEventInputSelectProps
    | RequiredCreateEventInputNumberProps

function RequiredCreateEventInput({
    onChange,
    onValidationChange,
    label,
    errorMessage,
    parentFormVariant,
    type,
    initialValue,
    ...props
}: RequiredCreateEventInputProps) {
    const [showError, setShowError] = useState(false)

    return (
        <FormControl isInvalid={showError}>
            <FormLabel
                fontSize="lg"
                color={parentFormVariant === 'white' ? 'plejGreen.500' : 'white'}
            >
                {label}
            </FormLabel>
            <RequiredCreateEventInputField
                onChange={onChange}
                onValidationChange={onValidationChange}
                onShowErrorChange={(newShowError) => setShowError(newShowError)}
                parentFormVariant={parentFormVariant}
                type={type as any}
                initialValue={initialValue}
                {...props}
            />
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
    )
}

export default RequiredCreateEventInput
