import { Heading, Text, VStack } from '@chakra-ui/react'

export interface ImageUploadFormSectionProps {
    sectionName: string
    description: string
}

function ImageUploadFormSection({
    sectionName,
    description,
    children,
}: React.PropsWithChildren<ImageUploadFormSectionProps>) {
    return (
        <VStack alignItems="start" spacing={4} mb={4} w="full">
            <Heading as="h3" fontSize="2xl" mb={2} textTransform="capitalize">
                {sectionName}
            </Heading>
            <Text fontSize="lg">{description}</Text>
            {children}
        </VStack>
    )
}

export default ImageUploadFormSection
