import './formatDate'

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export default function capitalizeSentence(sentence: string) {
    return sentence
        .split(' ')
        .map((word) => capitalizeFirstLetter(word))
        .join(' ')
}
