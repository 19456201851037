import { Button, HStack, IconButton, IconButtonProps } from '@chakra-ui/react'

export interface ButtonSectionProps {
    mainButton: {
        onClick?: React.MouseEventHandler<HTMLButtonElement>
        href?: string
        text: string
        disabled?: boolean
    }
    iconButton: {
        icon: IconButtonProps['icon']
        text: string
        onClick?: React.MouseEventHandler<HTMLButtonElement>
    }
}

function ButtonSection({ mainButton, iconButton }: ButtonSectionProps) {
    return (
        <HStack w="full" spacing="2">
            {mainButton.href ? (
                <Button
                    isDisabled={mainButton.disabled}
                    as="a"
                    href={mainButton.href}
                    bg="plejGreen.500"
                    flex={1}
                >
                    {mainButton.text}
                </Button>
            ) : (
                <Button onClick={mainButton.onClick} bg="plejGreen.500" flex={1}>
                    {mainButton.text}
                </Button>
            )}
            <IconButton
                icon={iconButton.icon}
                aria-label={iconButton.text}
                onClick={iconButton.onClick}
                variant="round"
            />
        </HStack>
    )
}

export default ButtonSection
