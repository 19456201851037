import CreateEventInput, {
    CreateEventInputPropsNumber,
    CreateEventInputPropsSelect,
    CreateEventInputTyping,
} from '@v1_atoms/CreateEventInput/CreateEventInput.component'
import { CreateEventSectionProps } from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import { useEffect, useState } from 'react'

interface BaseRequiredCreateEventInputFieldProps {
    onChange: (v: string) => void
    onValidationChange: (isValid: boolean) => void
    onShowErrorChange: (showError: boolean) => void
    parentFormVariant: CreateEventSectionProps['variant']
    initialValue?: string
    deleted?: boolean
}

interface RequiredCreateEventInputFieldTypingProps extends BaseRequiredCreateEventInputFieldProps {
    type: CreateEventInputTyping['type']
}

interface RequiredCreateEventInputFieldNumberProps extends BaseRequiredCreateEventInputFieldProps {
    type: CreateEventInputPropsNumber['type']
    precision?: CreateEventInputPropsNumber['precision']
}

interface RequiredCreateEventInputFieldSelectProps extends BaseRequiredCreateEventInputFieldProps {
    type: CreateEventInputPropsSelect['type']
    options: CreateEventInputPropsSelect['options']
    placeholder: string
}

export type RequiredCreateEventInputFieldProps =
    | RequiredCreateEventInputFieldTypingProps
    | RequiredCreateEventInputFieldSelectProps
    | RequiredCreateEventInputFieldNumberProps

function RequiredCreateEventInputField({
    onChange,
    onValidationChange,
    onShowErrorChange,
    parentFormVariant,
    type,
    initialValue,
    deleted,
    ...props
}: RequiredCreateEventInputFieldProps) {
    const [value, setValue] = useState(initialValue ?? '')
    const [touched, setTouched] = useState(false)

    const [showError, setShowError] = useState(false)
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        onChange(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        const newShowError = value === '' && touched

        if (newShowError !== showError) {
            setShowError(newShowError)
            onShowErrorChange(newShowError)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, touched])

    useEffect(() => {
        const newIsValid = value !== ''

        if (newIsValid !== isValid) {
            setIsValid(newIsValid)
            onValidationChange(newIsValid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    if (deleted) {
        return null
    }

    return (
        <CreateEventInput
            value={value}
            onBlur={() => {
                setTouched(true)
            }}
            onChange={(v) => setValue(v)}
            parentFormVariant={parentFormVariant}
            type={type as any}
            {...props}
        />
    )
}

export default RequiredCreateEventInputField
