import { Button, Divider, VStack } from '@chakra-ui/react'
import { CurrencyV1, EventStatusV1 } from '@commonwealthventures/poc-readstore-v1-models'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import formatCurrency from '../../../utils/formatCurrency'
import PledgePaymentTimeInfo from '../../atoms/PledgePaymentTimeInfo/PledgePaymentTimeInfo.component'
import TotalCost from '../../atoms/TotalCost/TotalCost.component'

const stripeApiKey = `${process.env.REACT_APP_CREATE_PAYMENT_INTENT_API_KEY}`

const callCreateCheckoutSession = async (
    eventId: string,
    quantity: number,
    priceId: string,
    productId: string,
    organiserId: string,
    ticketOptionIndex: number,
    status: EventStatusV1,
    navigator: NavigateFunction,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    setIsLoading(true)
    const queryParams = new URLSearchParams()

    if (status !== EventStatusV1.REQUIRES_FUNDING) {
        queryParams.append('instantCharge', 'true')
    }

    await fetch(
        `${process.env.REACT_APP_STRIPE_API_URL}/create-checkout-session?${queryParams.toString()}`,
        {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'x-api-key': stripeApiKey!,
            },
            body: JSON.stringify({
                stripe: {
                    priceId,
                    productId,
                },
                quantity,
                eventId,
                organiserId,
                ticketOptionIndex,
            }),
        },
    )
        .then((response) => {
            return response.json()
        })
        .then((session) => {
            setIsLoading(false)
            // 👇️ directly change the active URL to navigate
            navigator((window.location.href = session.session.url))
        })
        .catch((e) => {
            // setIsLoading(false)
            return e
        })
}

export interface CheckoutTotalSectionProps {
    eventId: string
    price: number
    currency: CurrencyV1
    deadline: Date
    status: EventStatusV1
    quantity: number
    priceId: string
    productId: string
    organiserId: string
    ticketOptionIndex: number
}

function CheckoutTotalSection({
    eventId,
    deadline,
    price,
    currency,
    status,
    quantity,
    priceId,
    productId,
    organiserId,
    ticketOptionIndex,
}: CheckoutTotalSectionProps) {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const totalCost = price * quantity

    return (
        <VStack w="full">
            <TotalCost price={totalCost} currency={currency} />
            {(status === EventStatusV1.REQUIRES_FUNDING ||
                status === EventStatusV1.REACHED_PLEDGE_GOAL) && (
                <>
                    <Divider />
                    <PledgePaymentTimeInfo
                        price={totalCost}
                        deadline={deadline}
                        currency={currency}
                        status={status}
                    />
                </>
            )}
            <Button
                mt="24px !important"
                isLoading={isLoading}
                onClick={() =>
                    callCreateCheckoutSession(
                        eventId,
                        quantity,
                        priceId,
                        productId,
                        organiserId,
                        ticketOptionIndex,
                        status,
                        navigate,
                        setIsLoading,
                    )
                }
            >
                {' '}
                {/* have to manually override the stack spacing for margin */}
                Confirm and pay {formatCurrency({ price: price * quantity, currency })}
            </Button>
        </VStack>
    )
}

export default CheckoutTotalSection
