import { Heading } from '@chakra-ui/react'
import RequiredCreateEventInput from '@v1_molecules/RequiredCreateEventInput/RequiredCreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import { useEffect, useState } from 'react'

export type OrganiserDetailsFormValues = {
    id: string
    name: string
}

export interface OrganiserDetailsFormProps extends CreateEventSectionProps {
    onChange: (details: OrganiserDetailsFormValues) => void
    onValidationChange: (isValid: boolean) => void
}

function OrganiserDetailsForm({
    variant,
    onChange,
    onValidationChange,
}: OrganiserDetailsFormProps) {
    const [id, setId] = useState({ value: '', isValid: false })
    const [name, setName] = useState({ value: '', isValid: false })

    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        onChange({
            id: id.value,
            name: name.value,
        })

        const newIsValid = id.isValid && name.isValid

        if (newIsValid !== isValid) {
            setIsValid(newIsValid)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, name])

    useEffect(() => {
        onValidationChange(isValid)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid])

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Organiser details
            </Heading>

            <RequiredCreateEventInput
                label="ID:"
                errorMessage="Organiser ID is required"
                onChange={(v) => setId({ value: v, isValid: id.isValid })}
                onValidationChange={(isIdValid) => setId({ value: id.value, isValid: isIdValid })}
                parentFormVariant={variant}
                type="text"
            />
            <RequiredCreateEventInput
                label="Name:"
                errorMessage="Organiser name is required"
                onChange={(v) => setName({ value: v, isValid: name.isValid })}
                onValidationChange={(isNameValid) =>
                    setName({ value: name.value, isValid: isNameValid })
                }
                parentFormVariant={variant}
                type="text"
            />
        </CreateEventSection>
    )
}

export default OrganiserDetailsForm
