import { ChakraProvider, Container } from '@chakra-ui/react'
import AddEventImages from '@v1_pages/AddEventImages/AddEventImages.component'
import ConfirmAndPay from '@v1_pages/ConfirmAndPay/ConfirmAndPay.component'
import ContactPage from '@v1_pages/Contact/ContactPage.component'
import CreateEventPage from '@v1_pages/CreateEvent/CreateEvent.component'
import V1EventPage from '@v1_pages/Event/EventPage.component'
import Events from '@v1_pages/Events/Events.component'
import HomePage from '@v1_pages/Home/HomePage.component'
import InvestorsPage from '@v1_pages/Investors/Investors.component'
import NotFound from '@v1_pages/NotFound/NotFound.component'
import PartnersPage from '@v1_pages/Partners/Partners.component'
import SuccessPage from '@v1_pages/Success/SuccessPage.component'
import TeamPage from '@v1_pages/Team/Team.component'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import theme from './theme'

const queryClient = new QueryClient()

function App() {
    return (
        <ChakraProvider theme={theme}>
            <Container boxSizing="border-box" w="100vw" maxW="1920px" m="0" p="0" alignSelf="start">
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/about" element={<HomePage />} />
                            <Route path="/team" element={<TeamPage />} />
                            <Route path="/partners" element={<PartnersPage />} />
                            <Route path="/contact" element={<ContactPage />} />
                            <Route path="/invest" element={<InvestorsPage />} />
                            <Route path="/admin/create-event" element={<CreateEventPage />} />
                            <Route
                                path="/admin/create-event/:id/images"
                                element={<AddEventImages />}
                            />
                            <Route path="/events" element={<Events />} />
                            <Route path="/events/:id" element={<V1EventPage />} />
                            <Route path="/events/:id/confirm" element={<ConfirmAndPay />} />
                            <Route path="/events/:id/success" element={<SuccessPage />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </BrowserRouter>
                </QueryClientProvider>
            </Container>
        </ChakraProvider>
    )
}

export default App
