import {
    HStack,
    Input,
    InputGroup,
    InputRightAddon,
    Stack,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react'
import FacebookShareButton from '@v1_atoms/FacebookShareButton/FacebookShareButton.component'
import TwitterXShareButton from '@v1_atoms/TwitterXShareButton/TwitterXShareButton.component'
import { MdContentCopy } from 'react-icons/md'

function SocialButtons({ shareUrl, message }: { shareUrl: string; message: string }) {
    const toast = useToast()

    const copyButtonFn = () => {
        navigator.clipboard.writeText(shareUrl)

        toast({
            title: 'Event URL copied!',
            status: 'info',
            duration: 2000,
            isClosable: false,
        })
    }

    return (
        <VStack w="full" justifyContent="start" alignItems="start">
            <Text color="white" fontSize="xl" fontWeight="medium">
                Tell your friends
            </Text>
            <Stack
                direction={{ base: 'column', md: 'row' }}
                w="full"
                p="0"
                pt="2"
                spacing="4"
                alignItems={{ base: 'start', md: 'center' }}
            >
                <HStack spacing="4">
                    <FacebookShareButton shareUrl={shareUrl} />
                    <TwitterXShareButton message={message} shareUrl={shareUrl} />
                </HStack>
                <InputGroup flex={1}>
                    <Input bg="white" value={shareUrl} />
                    <InputRightAddon
                        tabIndex={0}
                        cursor="pointer"
                        onClick={copyButtonFn}
                        onKeyUp={({ key }) => {
                            if (key === 'Enter') {
                                copyButtonFn()
                            }
                        }}
                    >
                        <MdContentCopy />
                    </InputRightAddon>
                </InputGroup>
            </Stack>
        </VStack>
    )
}

export default SocialButtons
