import { PostImageResponse } from '@commonwealthventures/image-service-api-models'
import axios from 'axios'

export type ImageField =
    | 'primary'
    | 'thank-you'
    | `providers/${string}`
    | 'organiser'
    | 'confirm/big'
    | 'confirm/medium'
    | 'confirm/small'

export default async function getS3SignedUrl(
    eventId: string,
    field: ImageField,
    authToken: string,
): Promise<
    Pick<PostImageResponse, 'tagHeaderToSet' | 'uploadUrl'> & {
        httpMethod: 'put' | 'post' | 'delete'
    }
> {
    const generatePresignedUrlResult = await axios.post<PostImageResponse>(
        `${process.env.REACT_APP_IMAGE_API_URL}/event/${eventId}/${field}`,
        null,
        {
            headers: {
                'x-API-Key': process.env.REACT_APP_IMAGE_API_KEY!,
                Authorization: authToken,
            },
        },
    )

    const { httpMethod, uploadUrl, tagHeaderToSet } = generatePresignedUrlResult.data

    const formattedHttpMethod = httpMethod.toLowerCase() as 'put' | 'post' | 'delete'

    return {
        httpMethod: formattedHttpMethod,
        uploadUrl,
        tagHeaderToSet,
    }
}
