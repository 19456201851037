import { Text, Flex, TypographyProps } from '@chakra-ui/react'

type SchpielTextProps = {
    text: string
    textColour?: string
    fontSize?: string
    textAlign?: TypographyProps['textAlign']
}

function SchpielText({
    text,
    textColour = 'white',
    fontSize = '18px',
    textAlign = 'left',
}: SchpielTextProps) {
    return (
        <Flex alignSelf="stretch" flexDirection="column">
            <Text
                color={textColour}
                fontSize={fontSize}
                fontStyle="normal"
                fontWeight="400"
                lineHeight="150%"
                textAlign={textAlign}
            >
                {text}
            </Text>
        </Flex>
    )
}

export default SchpielText
