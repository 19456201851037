import { VStack } from '@chakra-ui/react'

export interface CreateEventSectionProps {
    variant: 'white' | 'green'
}

function CreateEventSection({
    children,
    variant,
}: React.PropsWithChildren<CreateEventSectionProps>) {
    return (
        <VStack
            textColor={variant === 'white' ? 'plejGreen.500' : 'white'}
            alignItems="start"
            w="full"
            maxW="800px"
            spacing={4}
        >
            {children}
        </VStack>
    )
}

export default CreateEventSection
