import { Heading } from '@chakra-ui/react'

export enum SectionTitleType {
    KEY_DETAIL = 'keyDetail',
    SECTION = 'section',
}

function SectionTitle({ text, type }: { text: string; type: SectionTitleType }) {
    return (
        <Heading
            as="h2"
            fontSize={type === 'keyDetail' ? 'lg' : 'xl'}
            color={type === 'keyDetail' ? 'plejGrey.800' : 'plejGreen.500'}
            fontWeight={type === 'keyDetail' ? 400 : 500}
            borderBottomColor="plejGrey.400"
            borderBottomWidth="1pt"
            paddingBottom={type === 'keyDetail' ? '2' : '3'}
            w="full"
        >
            {text}
        </Heading>
    )
}

export default SectionTitle
