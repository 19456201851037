import { Box, Image, VStack } from '@chakra-ui/react'
import { GetEventImageMapResponse } from '@commonwealthventures/image-service-api-models'
import { EventStatusV1, EventV1 } from '@commonwealthventures/poc-readstore-v1-models'
import getImageMap from '@services/getImageMap.service'
import formatDate from '@utils/formatDate'
import DetailsTagline from '@v1_atoms/DetailsTagline/DetailsTagline.component'
import EventTitle from '@v1_atoms/EventTitle/EventTitle.component'
import FundProgress from '@v1_molecules/FundProgress/FundProgress.component'
import { useQuery } from 'react-query'

function EventResultItem({ event }: { event: EventV1 }) {
    const { data: imagesData } = useQuery<GetEventImageMapResponse>({
        queryKey: ['IMAGES', event.id],
        queryFn: async () => {
            return getImageMap(event.id)
        },
        retry: 3,
        retryDelay: 500,
    })

    const boxColor =
        event.status === EventStatusV1.FAILED_FUNDING ? 'plejGreen.100' : 'plejGreen.500'
    const barColor =
        event.status === EventStatusV1.FAILED_FUNDING ? 'plejGreen.500' : 'plejGrey.300'
    const trackColor =
        event.status === EventStatusV1.FAILED_FUNDING ? 'plejGrey.300' : 'plejGreen.600'
    const textColor = event.status === EventStatusV1.FAILED_FUNDING ? 'plejGreen.500' : 'white'

    const href = `${
        window.location.href.endsWith('/')
            ? window.location.href.slice(0, -1)
            : window.location.href
    }/${event.id}`

    return (
        <a href={href} id={`event-${event.id}`}>
            <VStack
                borderRadius="16px"
                minW="full"
                h="360px"
                bg={boxColor}
                spacing="0"
                overflow="hidden"
                alignItems="start"
            >
                <Image
                    w="full"
                    h="full"
                    fit="cover"
                    fallback={<Box w="full" h="full" bg={boxColor} />}
                    src={imagesData?.primary}
                    alt="Todo alt"
                />
                <VStack spacing="5" alignItems="start" p="4" pt="2" bg={boxColor} w="full">
                    <VStack spacing="1" alignItems="start">
                        <EventTitle
                            color={textColor}
                            size="lg"
                            text={event.title}
                            eventId={event.id}
                        />
                        <DetailsTagline
                            color={textColor}
                            dateText={formatDate(event.date)}
                            location={event.venue.city}
                            generalLocation={event.venue.country}
                        />
                    </VStack>
                    <FundProgress
                        status={event.status}
                        capacity={event.capacity}
                        currentAttendees={event.currentAttendees}
                        requiredTotalOfPledgedAttendees={event.requiredTotalOfPledgedAttendees}
                        pledgeEndDate={event.pledgeEndDate}
                        ticketSalesEndDate={event.ticketSalesEndDate}
                        type="summary"
                        barColor={barColor}
                        trackColor={trackColor}
                        textColor={textColor}
                    />
                </VStack>
            </VStack>
        </a>
    )
}

export default EventResultItem
