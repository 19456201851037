import { GetEventImageMapResponse } from '@commonwealthventures/image-service-api-models'
import axios from 'axios'

export default async function getImageMap(eventId: string) {
    const result = await axios.get<GetEventImageMapResponse>(
        `${process.env.REACT_APP_IMAGE_API_URL}/event/${eventId}`,
        {
            headers: {
                'x-api-key': process.env.REACT_APP_IMAGE_API_KEY!,
            },
        },
    )

    const updateData = (obj: Record<string, string | Record<string, string>>) => {
        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                updateData(obj[key] as Record<string, string>)
            } else if (obj[key] !== null) {
                // eslint-disable-next-line no-param-reassign
                obj[key] = `${process.env.REACT_APP_IMAGE_API_URL}/image/${obj[key]}`
            }
        })
    }

    updateData(result.data)

    return result.data
}
