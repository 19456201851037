import { Heading, HStack } from '@chakra-ui/react'
import NavDropDown from '@v1_molecules/NavDropDown/NavDropDown.component'

function NavHeading({
    headingText,
    menuColour,
}: {
    headingText: string
    menuColour: 'grey' | 'green'
}) {
    return (
        <HStack justifyContent="space-between" w="full" pl="4">
            <Heading
                as="h1"
                textTransform="capitalize"
                fontWeight="medium"
                fontSize="3xl"
                textColor={menuColour === 'grey' ? 'white' : 'plejGreen.500'}
            >
                <a href="/">{headingText}</a>
            </Heading>
            <NavDropDown menuColour={menuColour} />
        </HStack>
    )
}

export default NavHeading
