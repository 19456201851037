import { IconButton } from '@chakra-ui/react'
import { FaFacebook } from 'react-icons/fa6'

function FacebookShareButton({ shareUrl }: { shareUrl: string }) {
    return (
        <IconButton
            flex={0}
            aria-label="share to facebook"
            colorScheme="facebook"
            icon={<FaFacebook />}
            data-href={shareUrl}
            as="a"
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                shareUrl,
            )}&amp;src=sdkpreparse`}
            className="fb-xfbml-parse-ignore"
            target="_blank"
            rel="noreferrer"
        />
    )
}

export default FacebookShareButton
