import { Text, VStack } from '@chakra-ui/react'
import ParticipantImage from '../../atoms/ParticipantImage/ParticipantImage.component'

function TeamMember({ name, src, bio }: { name: string; src?: string; bio?: string }) {
    return (
        <VStack alignItems="start" spacing="2">
            <ParticipantImage name={name} src={src} variant="provider" />
            <Text>{bio}</Text>
        </VStack>
    )
}

export default TeamMember
