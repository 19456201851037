import { Box, Image } from '@chakra-ui/react'

function FundImage({ src, alt }: { src?: string; alt?: string }) {
    return (
        <Box w="100vw" h="100vh" position="fixed" left="0px" top="0px" zIndex="-1">
            <Image
                w="full"
                h="full"
                position="absolute"
                src={src}
                alt={alt}
                fallback={<Box w="full" h="full" bg="plejGreen.500" />}
                fit="cover"
            />
            <Box
                w="full"
                h="full"
                position="absolute"
                bgGradient="linear(to-b, rgba(0,0,0,0) 30%, black)"
            />
        </Box>
    )
}

export default FundImage
