import { Flex, Text } from '@chakra-ui/react'
import { CurrencyV1, EventStatusV1 } from '@commonwealthventures/poc-readstore-v1-models'
import Months from '../../../constants/Months'
import formatCurrency from '../../../utils/formatCurrency'

export interface PledgePaymentTimeInfoProps {
    price: number
    currency: CurrencyV1
    deadline: Date
    status: EventStatusV1.REQUIRES_FUNDING | EventStatusV1.REACHED_PLEDGE_GOAL
}

function PledgePaymentTimeInfo({ deadline, price, currency, status }: PledgePaymentTimeInfoProps) {
    return (
        <Flex>
            {status === EventStatusV1.REQUIRES_FUNDING ? (
                <Text>
                    If the event reaches its funding goal before{' '}
                    <b>
                        {deadline.getDate()} {Months[deadline.getMonth()]} {deadline.getFullYear()}
                    </b>
                    , you will be charged <b>{formatCurrency({ price, currency })}</b>.
                </Text>
            ) : (
                <Text>
                    This event has already reached its funding goal, you will be charged now.
                </Text>
            )}
        </Flex>
    )
}

export default PledgePaymentTimeInfo
