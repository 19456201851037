import { Flex, Text } from '@chakra-ui/react'
import { CurrencyV1 } from '@commonwealthventures/poc-readstore-v1-models'
import formatCurrency from '../../../utils/formatCurrency'

interface TotalCostProps {
    price: number
    currency: CurrencyV1
}

function TotalCost({ price, currency }: TotalCostProps) {
    return (
        <Flex w="full" alignItems="center" justifyContent="space-between">
            <Text>Total</Text>
            <Text fontSize="lg" fontWeight={500}>
                {formatCurrency({ price, currency })}
            </Text>
        </Flex>
    )
}

export default TotalCost
