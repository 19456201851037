import { Avatar, Heading, Text, VStack } from '@chakra-ui/react'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import Andy from './Andy.jpeg'
import George from './George.jpeg'
import Harry from './Harry.jpeg'
import Sean from './Sean.jpeg'

const teamDetails = [
    {
        name: 'Sean Harris',
        image: Sean,
        bio: 'Sean was inspired to bring together a team to build the future of crowdfunding payments and up end the tired, broken, traditional model of promoted events, un- and under- utililised venues. He wants to find the lost revenue streams for creators. Then he wants to cycle the Grand Divide bike packing route.',
    },
    {
        name: 'Andy Hurt',
        image: Andy,
        bio: 'Andy spent 4 years at IBM making sick blockchain stuff & opensourcing on Linux foundation projects. For his sins, he’s a Derby Country devotee and enjoys walking in the greatest country (Derbyshire incase anyone from Yorkshire gets confused).',
    },
    {
        name: 'George Jones',
        image: George,
        bio: 'George grabbed a Masters in Mechanical Engineering from Cardif and cut his teeth as a platform & devops engineer before switching k8s for the world of serverless. Likes snowboarding, travelling, watching sports - and playing them when he can. Probably has the best lid on the team much to our chagrin.',
    },
    {
        name: 'Harry Fry',
        image: Harry,
        bio: 'Harry collects ParkRun wins for fun. The kind of guy who will try to make a Mr Whippy look like something out of a fashion shoot but don’t hold it against him. Experienced enginering consultant and front-end fu guy. Maintains a real time ranking of the best types of beers with shower beers famously performing consistently highly.',
    },
]

function TeamPage() {
    return (
        <VStack minH="100vh" spacing={8} pb="4" color="white" bg="plejGreen.500">
            <NavHeading menuColour="grey" headingText="Turbafy" />
            <VStack spacing={8} maxW="800px">
                <VStack px="4" mb="8" w="full" alignItems="start">
                    <Heading as="h2">Our Team</Heading>
                    <Text>
                        We&apos;re working to help make great events happen in great places.
                    </Text>
                </VStack>
                {teamDetails.map(({ name, image, bio }) => (
                    <VStack px="4" spacing="4" w="full">
                        <Heading as="h2">{name}</Heading>
                        <Avatar name={name} src={image} size="2xl" />
                        <Text textAlign="center">{bio}</Text>
                    </VStack>
                ))}
            </VStack>
        </VStack>
    )
}

export default TeamPage
