import { VStack } from '@chakra-ui/react'
import SectionTitle, { SectionTitleType } from '../../atoms/SectionTitle/SectionTitle.component'
import TeamMember from '../TeamMember/TeamMember.component'

function OrganiserSection({ name, src }: { name: string; src?: string }) {
    return (
        <VStack w="full" alignItems="start" spacing="2">
            <SectionTitle text="Organiser" type={SectionTitleType.KEY_DETAIL} />
            <TeamMember name={name} src={src} />
        </VStack>
    )
}

export default OrganiserSection
