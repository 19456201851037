import Result from '@defs/Result'
import axios, { AxiosError } from 'axios'
import getS3SignedUrl, { ImageField } from './getS3SignedUrl.service'

export default async function uploadImage(
    eventId: string,
    field: ImageField,
    file: File,
    authToken: string,
    onUploadProgress: (loaded: number) => void,
): Promise<Result<void>> {
    const { uploadUrl, tagHeaderToSet } = await getS3SignedUrl(eventId, field, authToken)

    try {
        await axios.put(uploadUrl, file, {
            headers: {
                'Content-Type': file.type,
                'x-amz-tagging': tagHeaderToSet,
            },
            onUploadProgress: (uploadEvent) => {
                onUploadProgress(uploadEvent.loaded * 100)
            },
        })
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error uploading image. ERROR:', (err as AxiosError).toJSON())

        return {
            success: false,
            error: 'failed to upload image',
        }
    }

    return {
        success: true,
    }
}
