import { Avatar, Flex, Text } from '@chakra-ui/react'

interface ParticipantImageProps {
    name: string
    src?: string
    variant: 'organiser' | 'provider'
}

function ParticipantImage({ name, src, variant }: ParticipantImageProps) {
    return (
        <Flex gap={4} alignItems="center">
            <Avatar size={variant === 'organiser' ? 'md' : 'lg'} name={name} src={src} />
            <Text fontSize={variant === 'organiser' ? 'lg' : 'xl'}>{name}</Text>
        </Flex>
    )
}

export default ParticipantImage
