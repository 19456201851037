import { Box, Container, VStack } from '@chakra-ui/react'
import { EventStatusV1 } from '@commonwealthventures/poc-readstore-v1-models'
import formatCurrency from '@utils/formatCurrency'
import formatDate from '@utils/formatDate'
import EventTitle from '@v1_atoms/EventTitle/EventTitle.component'
import SelectBox from '@v1_atoms/SelectBox/SelectBox.component'
import EventQueryingPage, { EventWithImages } from '@v1_hocs/EventQueryingPage.component'
import CheckoutImages from '@v1_molecules/CheckoutImages/CheckoutImages.component'
import CheckoutTotalSection from '@v1_molecules/CheckoutTotalSection/CheckoutTotalSection.component'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'

function ConfirmAndPayContents({ data }: { data: EventWithImages }) {
    const {
        id,
        images,
        date,
        ticketOptions,
        status,
        pledgeEndDate,
        currentAttendees,
        capacity,
        organiser,
        title,
    } = data

    const maxQuantity = Math.min(6, Math.max(0, capacity - currentAttendees))

    const [currentTicketOption, setCurrentTicketOption] = useState(ticketOptions[0])
    const [currentTicketOptionIndex, setCurrentTicketOptionIndex] = useState(0)
    const [quantity, setQuantity] = useState(1)

    if (
        status === EventStatusV1.FAILED_FUNDING ||
        status === EventStatusV1.SOLD_OUT ||
        status === EventStatusV1.TICKETS_OFF_SALE
    ) {
        return <Navigate to={`/events/${id}`} />
    }

    return (
        <Container p={0} m={0} bg="plejGrey.300" minW="100vw">
            <Container p="0">
                <VStack p="4" minH="100vh" spacing="4" alignItems="start">
                    <CheckoutImages
                        bigImage={{
                            src: images.confirm?.big,
                        }}
                        mediumImage={{
                            src: images.confirm?.medium,
                        }}
                        smallImage={{
                            src: images.confirm?.small,
                        }}
                    />
                    <EventTitle color="plejGreen.500" text={title} />
                    <SelectBox
                        onChange={() => undefined}
                        label="Select date"
                        options={[{ text: formatDate(date) }]}
                    />
                    <SelectBox
                        label="Select ticket option"
                        options={ticketOptions.map((option, idx) => ({
                            text: `${option.name} — ${formatCurrency(option)}`,
                            value: idx.toString(),
                        }))}
                        onChange={(optionIdx) => {
                            const index = Number.parseInt(optionIdx, 10)

                            setCurrentTicketOption(ticketOptions[index])
                            setCurrentTicketOptionIndex(index)
                        }}
                    />
                    <SelectBox
                        label="Select quantity"
                        options={new Array(maxQuantity).fill(undefined).map((_, idx) => ({
                            text: (idx + 1).toString(),
                            value: (idx + 1).toString(),
                        }))}
                        onChange={(selectedQuantity) => {
                            setQuantity(Number.parseInt(selectedQuantity, 10))
                        }}
                    />
                    <Box pt="6" w="full">
                        <CheckoutTotalSection
                            eventId={id}
                            deadline={new Date(pledgeEndDate)}
                            price={currentTicketOption.price}
                            currency={currentTicketOption.currency}
                            quantity={quantity}
                            status={status}
                            ticketOptionIndex={currentTicketOptionIndex}
                            organiserId={organiser.id}
                            priceId={currentTicketOption.stripe.priceId}
                            productId={currentTicketOption.stripe.productId}
                        />
                    </Box>
                </VStack>
            </Container>
        </Container>
    )
}

export default function ConfirmAndPay() {
    return (
        <EventQueryingPage>
            {(data: EventWithImages) => <ConfirmAndPayContents data={data} />}
        </EventQueryingPage>
    )
}
