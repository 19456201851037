import { Heading } from '@chakra-ui/react'
import RequiredCreateEventInput from '@v1_molecules/RequiredCreateEventInput/RequiredCreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import { useEffect, useState } from 'react'

export type FundDetailsFormValues = {
    fundingDeadline: string
    traditionalPurchaseDeadline: string
    fundingThreshold: number
    maxPlaces: number
}

export interface FundDetailsFormProps extends CreateEventSectionProps {
    onChange: (details: FundDetailsFormValues) => void
    onValidationChange: (isValid: boolean) => void
}

function FundDetailsForm({ variant, onChange, onValidationChange }: FundDetailsFormProps) {
    const [fundingDeadline, setFundingDeadline] = useState({ value: '', isValid: false })
    const [traditionalPurchaseDeadline, setTraditionalPurchaseDeadline] = useState({
        value: '',
        isValid: false,
    })
    const [fundingThreshold, setFundingThreshold] = useState({ value: '', isValid: false })
    const [maxPlaces, setMaxPlaces] = useState({ value: '', isValid: false })

    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        onChange({
            fundingDeadline: fundingDeadline.value,
            traditionalPurchaseDeadline: traditionalPurchaseDeadline.value,
            fundingThreshold: Number.parseInt(fundingThreshold.value, 10),
            maxPlaces: Number.parseInt(maxPlaces.value, 10),
        })

        const newIsValid =
            fundingDeadline.isValid &&
            traditionalPurchaseDeadline.isValid &&
            fundingThreshold.isValid &&
            maxPlaces.isValid

        if (newIsValid !== isValid) {
            setIsValid(newIsValid)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fundingDeadline, traditionalPurchaseDeadline, fundingThreshold, maxPlaces])

    useEffect(() => {
        onValidationChange(isValid)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid])

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Fund details
            </Heading>
            <RequiredCreateEventInput
                label="Funding deadline:"
                errorMessage="Funding deadline required"
                onChange={(v) => setFundingDeadline({ value: v, isValid: fundingDeadline.isValid })}
                onValidationChange={(isFundingDeadlineValid) =>
                    setFundingDeadline({
                        value: fundingDeadline.value,
                        isValid: isFundingDeadlineValid,
                    })
                }
                parentFormVariant={variant}
                type="date"
            />

            <RequiredCreateEventInput
                label="Traditional purchase deadline:"
                errorMessage="Traditional purchase deadline is required"
                onChange={(v) =>
                    setTraditionalPurchaseDeadline({
                        value: v,
                        isValid: traditionalPurchaseDeadline.isValid,
                    })
                }
                onValidationChange={(isTraditionalPurchaseDeadlineValid) =>
                    setTraditionalPurchaseDeadline({
                        value: traditionalPurchaseDeadline.value,
                        isValid: isTraditionalPurchaseDeadlineValid,
                    })
                }
                parentFormVariant={variant}
                type="date"
            />
            <RequiredCreateEventInput
                label="Funding threshold:"
                errorMessage="Funding threshold is required"
                onChange={(v) =>
                    setFundingThreshold({ value: v, isValid: fundingThreshold.isValid })
                }
                onValidationChange={(isFundingThresholdValid) =>
                    setFundingThreshold({
                        value: fundingThreshold.value,
                        isValid: isFundingThresholdValid,
                    })
                }
                parentFormVariant={variant}
                type="number"
            />

            <RequiredCreateEventInput
                label="Maximum number of places available"
                errorMessage="Max places is required"
                onChange={(v) => setMaxPlaces({ value: v, isValid: maxPlaces.isValid })}
                onValidationChange={(isMaxPlacesValid) =>
                    setMaxPlaces({ value: maxPlaces.value, isValid: isMaxPlacesValid })
                }
                parentFormVariant={variant}
                type="number"
            />
        </CreateEventSection>
    )
}

export default FundDetailsForm
