import { Flex, Icon, Text } from '@chakra-ui/react'
import { IconType } from 'react-icons'

interface IconTextProps {
    text: string
    icon: IconType
}

function IconText({ text, icon }: IconTextProps) {
    return (
        <Flex gap={4} alignItems="center">
            <Icon as={icon} boxSize={6} color="plejGreen.500" />
            <Text>{text}</Text>
        </Flex>
    )
}

export default IconText
