import { FormControl, FormLabel, Select } from '@chakra-ui/react'

export interface SelectBoxProps {
    label: string
    options: {
        text: string
        value?: string
    }[]
    onChange: (value: string) => void
}

function SelectBox({ label, options, onChange }: SelectBoxProps) {
    return (
        <FormControl
            p="4"
            borderRadius="4px"
            borderWidth="1pt"
            bg="white"
            borderColor="plejGrey.400"
            position="relative"
            _focusWithin={{
                outline: '5px auto',
            }}
        >
            <FormLabel position="absolute" m="0" color="plejGrey.800" fontWeight="400">
                {label}
            </FormLabel>
            <Select
                borderTopWidth="1.75rem"
                borderTopColor="transparent"
                size="lg"
                variant="unstyled"
                onChange={(e) => onChange(e.currentTarget.value)}
                id={label.split(' ').join('-').toLowerCase()}
            >
                {options.map(({ text, value }) => (
                    <option value={value !== undefined ? value : text}>{text}</option>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectBox
