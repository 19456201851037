import {
    Button,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Icon,
    IconButton,
    VStack,
} from '@chakra-ui/react'
import CreateEventInput from '@v1_atoms/CreateEventInput/CreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import MultiSectionInput from '@v1_molecules/MultiSectionInput/MultiSectionInput.component'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import RequiredCreateEventInput from '@v1_molecules/RequiredCreateEventInput/RequiredCreateEventInput.component'

export type AdditionalInformationFormSubSectionValues = {
    sectionTitle: string
    text: string[]
}

export type AdditionalInformationFormSectionValues = {
    title: string
    subSections: AdditionalInformationFormSubSectionValues[]
}

export type AdditionalInformationFormValues = {
    sections: AdditionalInformationFormSectionValues[]
}

export interface AdditionalInformationFormProps extends CreateEventSectionProps {
    onChange: (details: AdditionalInformationFormValues) => void
    onValidationChange: (isValid: boolean) => void
}

function AdditionalInformationForm({
    variant,
    onChange,
    onValidationChange,
}: AdditionalInformationFormProps) {
    const [sections, setSections] = useState<
        {
            title: { value: string; isValid: boolean }
            isDeleted: boolean
            subSections: {
                isDeleted: boolean
                sectionTitle: string
                text: { value: string[]; isValid: boolean }
            }[]
        }[]
    >([
        {
            title: { value: '', isValid: false },
            isDeleted: false,
            subSections: [
                {
                    isDeleted: false,
                    sectionTitle: '',
                    text: { value: [''], isValid: false },
                },
            ],
        },
    ])

    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        onChange({
            sections: sections
                .filter((section) => !section.isDeleted)
                .map((section) => ({
                    title: section.title.value,
                    subSections: section.subSections.map((subSection) => ({
                        sectionTitle: subSection.sectionTitle,
                        text: subSection.text.value,
                    })),
                })),
        })

        const newIsValid = sections.every(
            (section) =>
                section.isDeleted ||
                (section.title.isValid &&
                    section.subSections.every((subSection) => subSection.text.isValid)),
        )

        if (newIsValid !== isValid) {
            onValidationChange(newIsValid)
            setIsValid(newIsValid)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sections])

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Additional information:
            </Heading>
            {sections.map((section, idx) => (
                <VStack
                    mt={section.isDeleted ? -4 : undefined}
                    w="full"
                    alignItems="start"
                    spacing={4}
                    display={section.isDeleted ? 'none' : 'flex'}
                >
                    <HStack spacing={4}>
                        <Heading as="h4" fontSize="xl">
                            Section{' '}
                            {idx -
                                sections.slice(0, idx).filter(({ isDeleted }) => isDeleted).length +
                                1}
                        </Heading>
                        {sections.filter(({ isDeleted }) => !isDeleted).length > 1 ? (
                            <IconButton
                                icon={<MdDelete />}
                                aria-label="delete section"
                                minW={8}
                                w={8}
                                minH={8}
                                h={8}
                                p={0}
                                onClick={() => {
                                    const newSections = _.cloneDeep(sections)
                                    newSections[idx].isDeleted = true

                                    setSections(newSections)
                                }}
                            />
                        ) : null}
                    </HStack>
                    <RequiredCreateEventInput
                        type="text"
                        parentFormVariant={variant}
                        label="Title:"
                        errorMessage="Title is required"
                        onChange={(v) => {
                            const newSections = _.cloneDeep(sections)
                            newSections[idx].title.value = v

                            setSections(newSections)
                        }}
                        onValidationChange={(isTitleValid) => {
                            const newSections = _.cloneDeep(sections)
                            newSections[idx].title.isValid = isTitleValid

                            setSections(newSections)
                        }}
                    />
                    {section.subSections.map((subSection, subIdx) => (
                        <VStack
                            mt={subSection.isDeleted ? -4 : undefined}
                            spacing={4}
                            w="full"
                            alignItems="start"
                            display={subSection.isDeleted ? 'none' : 'flex'}
                        >
                            <HStack spacing={4}>
                                <Heading as="h5" fontSize="lg">
                                    Subsection{' '}
                                    {subIdx -
                                        section.subSections
                                            .slice(0, subIdx)
                                            .filter(({ isDeleted }) => isDeleted).length +
                                        1}
                                </Heading>
                                {section.subSections.filter(({ isDeleted }) => !isDeleted).length >
                                1 ? (
                                    <IconButton
                                        icon={<MdDelete />}
                                        aria-label="delete subsection"
                                        minW={8}
                                        w={8}
                                        minH={8}
                                        h={8}
                                        p={0}
                                        onClick={() => {
                                            const newSections = _.cloneDeep(sections)
                                            newSections[idx].subSections[subIdx].isDeleted = true

                                            setSections(newSections)
                                        }}
                                    />
                                ) : null}
                            </HStack>
                            <FormControl>
                                <FormLabel>Subsection title (not required):</FormLabel>
                                <CreateEventInput
                                    type="text"
                                    parentFormVariant={variant}
                                    value={subSection.sectionTitle}
                                    onChange={(v) => {
                                        const newSections = _.cloneDeep(sections)
                                        newSections[idx].subSections[subIdx].sectionTitle = v

                                        setSections(newSections)
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Subsection text:</FormLabel>
                                <MultiSectionInput
                                    parentFormVariant={variant}
                                    type="textarea"
                                    addButtonText="Add another paragraph"
                                    errorMessage="All subsections text fields must have a value"
                                    onChange={(values) => {
                                        const newSections = _.cloneDeep(sections)
                                        newSections[idx].subSections[subIdx].text.value = values

                                        setSections(newSections)
                                    }}
                                    onValidationChange={(isTextValid) => {
                                        const newSections = _.cloneDeep(sections)
                                        newSections[idx].subSections[subIdx].text.isValid =
                                            isTextValid

                                        setSections(newSections)
                                    }}
                                />
                            </FormControl>
                        </VStack>
                    ))}
                    <Button
                        mb="-4"
                        variant="textButton"
                        onClick={() => {
                            const newSections = _.cloneDeep(sections)
                            newSections[idx].subSections = [
                                ...newSections[idx].subSections,
                                {
                                    sectionTitle: '',
                                    isDeleted: false,
                                    text: { value: [''], isValid: false },
                                },
                            ]
                            setSections(newSections)
                        }}
                        fontSize="md"
                    >
                        <Icon as={MdAdd} boxSize={4} /> Add another subsection
                    </Button>
                </VStack>
            ))}
            <Button
                mb="-4"
                variant="textButton"
                onClick={() =>
                    setSections([
                        ...sections,
                        {
                            title: { value: '', isValid: false },
                            isDeleted: false,
                            subSections: [
                                {
                                    isDeleted: false,
                                    sectionTitle: '',
                                    text: { value: [''], isValid: false },
                                },
                            ],
                        },
                    ])
                }
                fontSize="md"
            >
                <Icon as={MdAdd} boxSize={4} /> Add another section
            </Button>
        </CreateEventSection>
    )
}

export default AdditionalInformationForm
