import { Box, Icon, IconButton, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { MdMenu } from 'react-icons/md'
import NavMenuOptions from './NavMenuOptions'

function NavDropDown({ menuColour }: { menuColour: 'grey' | 'green' }) {
    const [isOpen, setIsOpen] = useState(false)

    const primary = menuColour === 'grey' ? 'plejGreen.500' : 'plejGrey.300'
    const secondary = menuColour === 'grey' ? 'plejGrey.300' : 'plejGreen.500'

    return (
        <Box position="relative">
            <IconButton
                bg={isOpen ? secondary : primary}
                borderRadius="none"
                _hover={{
                    bg: secondary,
                    svg: {
                        fill: primary,
                    },
                }}
                boxShadow="none"
                icon={<Icon as={MdMenu} boxSize="8" color={isOpen ? primary : secondary} />}
                aria-label="open close navigation menu"
                w="16"
                h="16"
                id="navMenuButton"
                onClick={() => setIsOpen(!isOpen)}
            />
            <Box
                position="fixed"
                left="0"
                w="100vw"
                h="calc(100vh - var(--chakra-sizes-16))"
                bg={secondary}
                zIndex="99"
                visibility={{ base: isOpen ? 'visible' : 'hidden', lg: 'hidden' }}
            />
            <VStack
                visibility={isOpen ? 'visible' : 'hidden'}
                position="absolute"
                right="0px"
                bg={secondary}
                minW={{ base: '100vw', lg: '400px' }}
                borderRadius="0"
                border="none"
                fontSize="xl"
                spacing={0}
                borderLeftWidth={{ base: '0', lg: '1px' }}
                borderLeftColor={primary}
                borderLeftStyle="solid"
                borderBottomColor={primary}
                borderBottomWidth="1px"
                borderBottomStyle="solid"
                zIndex="100"
                id="navMenu"
            >
                {NavMenuOptions.map((name, idx, arr) => (
                    <Box
                        w="full"
                        textAlign="center"
                        textTransform="capitalize"
                        bg={secondary}
                        color={primary}
                        as="a"
                        href={`/${name}`}
                        _hover={{
                            textDecoration: 'underline',
                        }}
                        _focus={{
                            textDecoration: 'underline',
                        }}
                        borderBottomColor={primary}
                        borderBottomWidth={idx === arr.length - 1 ? '0px' : '1px'}
                        py={3}
                        px={4}
                        m={0}
                    >
                        {name}
                    </Box>
                ))}
            </VStack>
        </Box>
    )
}

export default NavDropDown
