/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The current status of the event
 */
export enum EventStatusV1 {
    REQUIRES_FUNDING = 'requires_funding',
    FAILED_FUNDING = 'failed_funding',
    REACHED_PLEDGE_GOAL = 'reached_pledge_goal',
    SOLD_OUT = 'sold_out',
    TICKETS_OFF_SALE = 'tickets_off_sale',
}
