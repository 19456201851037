import { VStack } from '@chakra-ui/react'
import { TicketOptionV1 } from '@commonwealthventures/poc-readstore-v1-models'
import EventOption from '../../atoms/EventOption/EventOption.component'
import SectionTitle, { SectionTitleType } from '../../atoms/SectionTitle/SectionTitle.component'

function TicketOptionsSection({
    optionsType,
    eventOptions,
}: {
    optionsType: string
    eventOptions: TicketOptionV1[]
}) {
    return (
        <VStack w="full" alignItems="start" spacing="2">
            <SectionTitle text={`${optionsType} options`} type={SectionTitleType.KEY_DETAIL} />
            <VStack alignItems="start" spacing="2" w="full">
                {eventOptions.map(({ name, price, currency }) => (
                    <EventOption optionName={name} price={price} currency={currency} />
                ))}
            </VStack>
        </VStack>
    )
}

export default TicketOptionsSection
