import { ColorProps, Flex, FlexboxProps, VStack } from '@chakra-ui/react'

function InfoSection({
    children,
    maxCenterWidth,
    bg,
    flex,
}: React.PropsWithChildren<{
    maxCenterWidth: string
    bg?: ColorProps['color']
    flex?: FlexboxProps['flex']
}>) {
    return (
        <Flex
            bg={bg}
            flexDirection="column"
            alignItems="center"
            justifyContent="start"
            p="10"
            w="full"
            flex={flex}
        >
            <VStack maxW={maxCenterWidth} spacing="6">
                {children}
            </VStack>
        </Flex>
    )
}

export default InfoSection
