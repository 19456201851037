import { VStack } from '@chakra-ui/react'
import { GetEventImageMapResponse } from '@commonwealthventures/image-service-api-models'
import { ProviderV1 } from '@commonwealthventures/poc-readstore-v1-models'
import generateProviderImageKey from '@utils/generateProviderImageKey'
import SectionTitle, { SectionTitleType } from '../../atoms/SectionTitle/SectionTitle.component'
import TeamMember from '../../molecules/TeamMember/TeamMember.component'

function TeamMembers({
    teamMembers,
    images,
}: {
    teamMembers: ProviderV1[]
    images: GetEventImageMapResponse['providers']
}) {
    return (
        <VStack w="full" alignItems="start" spacing="2">
            <SectionTitle text="Meet the team" type={SectionTitleType.SECTION} />
            <VStack alignItems="start" pt="2" spacing="6">
                {teamMembers.map(({ name, bio }, idx) => (
                    <TeamMember
                        name={name}
                        bio={bio}
                        src={images?.[generateProviderImageKey({ name, idx })]}
                    />
                ))}
            </VStack>
        </VStack>
    )
}

export default TeamMembers
