import { As, ColorProps, Text } from '@chakra-ui/react'

export interface EventTitleProps {
    text: string
    color?: ColorProps['color']
    size?: 'lg' | 'xl'
    weight?: number
    eventId?: string
}

function EventTitle({
    text,
    color = 'white',
    size = 'xl',
    weight = 500,
    eventId,
}: EventTitleProps) {
    const options = eventId
        ? {
              as: 'a' as As,
              href: `${
                  window.location.href.endsWith('/')
                      ? window.location.href.slice(0, -1)
                      : window.location.href
              }/${eventId}`,
              _hover: {
                  textDecoration: 'underline',
              },
          }
        : { as: 'h1' as As, href: undefined }

    return (
        <Text
            {...options}
            fontSize={size === 'xl' ? '3xl' : '2xl'}
            lineHeight={size === 'xl' ? '2.25rem' : '1.75rem'}
            color={color}
            fontWeight={weight}
            textTransform="capitalize"
        >
            {text}
        </Text>
    )
}

export default EventTitle
