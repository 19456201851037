import { Flex, Text } from '@chakra-ui/react'
import { CurrencyV1 } from '@commonwealthventures/poc-readstore-v1-models'
import formatCurrency from '../../../utils/formatCurrency'

function EventOption({
    optionName,
    price,
    currency,
}: {
    optionName: string
    price: number
    currency: CurrencyV1
}) {
    return (
        <Flex w="full" justifyContent="space-between">
            <Text>{optionName}</Text>
            <Text>{formatCurrency({ price, currency })}</Text>
        </Flex>
    )
}

export default EventOption
