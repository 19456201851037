import { Input, Select, Textarea } from '@chakra-ui/react'
import { CreateEventSectionProps } from '@v1_organisms/CreateEventSection/CreateEventSection.component'

interface BaseCreateEventInputProps {
    parentFormVariant: CreateEventSectionProps['variant']
    value: string
    onChange: (v: string) => void
    onBlur?: () => void
}

export interface CreateEventInputTyping extends BaseCreateEventInputProps {
    type: 'text' | 'date' | 'datetime-local' | 'textarea'
}

export interface CreateEventInputPropsNumber extends BaseCreateEventInputProps {
    type: 'number'
    precision?: number
}

export interface CreateEventInputPropsSelect extends BaseCreateEventInputProps {
    type: 'select'
    options: string[]
    placeholder: string
}

export type CreateEventInputProps =
    | CreateEventInputTyping
    | CreateEventInputPropsSelect
    | CreateEventInputPropsNumber

function CreateEventInput({
    parentFormVariant,
    type,
    value,
    onChange,
    onBlur,
    ...props
}: CreateEventInputProps) {
    const borderColor = parentFormVariant === 'white' ? 'plejGreen.500' : 'white'
    const hoverBorderColor = parentFormVariant === 'white' ? 'plejGreen.200' : 'plejGreen.300'
    const size = 'lg'
    const width = 'full'

    if (type === 'text' || type === 'date' || type === 'datetime-local') {
        return (
            <Input
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                size={size}
                borderColor={borderColor}
                _hover={{
                    borderColor: hoverBorderColor,
                }}
                w={width}
                type={type}
            />
        )
    }
    if (type === 'number') {
        return (
            <Input
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                size={size}
                borderColor={borderColor}
                _hover={{
                    borderColor: hoverBorderColor,
                }}
                w={width}
                type={type}
                inputMode="numeric"
            />
        )
    }
    if (type === 'select') {
        return (
            <Select
                borderColor={borderColor}
                _hover={{
                    borderColor: hoverBorderColor,
                }}
                onBlur={onBlur}
                size={size}
                w={width}
                placeholder={(props as CreateEventInputPropsSelect).placeholder}
                onChange={(e) => onChange(e.target.value)}
            >
                {(props as CreateEventInputPropsSelect).options.map((name) => (
                    <option>{name}</option>
                ))}
            </Select>
        )
    }

    return (
        <Textarea
            size={size}
            borderColor={borderColor}
            onBlur={onBlur}
            _hover={{
                borderColor: hoverBorderColor,
            }}
            w={width}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    )
}

export default CreateEventInput
