import { IconButton } from '@chakra-ui/react'
import { FaXTwitter } from 'react-icons/fa6'

function TwitterXShareButton({ shareUrl, message }: { shareUrl: string; message: string }) {
    return (
        <IconButton
            flex={0}
            aria-label="share to X previously twitter"
            bg="gray.900"
            _hover={{
                bg: 'gray.800',
            }}
            icon={<FaXTwitter />}
            as="a"
            href={`https://twitter.com/intent/tweet?text=${message}&url=${shareUrl}`}
            className="twitter-share-button"
            target="_blank"
            rel="noreferrer"
        />
    )
}

export default TwitterXShareButton
