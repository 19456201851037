import { Button, Heading, Text, VStack } from '@chakra-ui/react'
import Partner from '@v1_atoms/Partner/Partner.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'

const partnerDetails = [
    {
        name: 'Megan’s Yoga',
        image: '/images/yoga.jpg',
        bio: 'Megan is de-risking and funding specialised cycoyoga sessions and boutique foreign yoga retreats with Turbafy.',
    },
    {
        name: 'Your side gig',
        image: '/images/yoga_thankyou.jpg',
        bio: 'If you’ve got an audience, an act, a performance, a routine, a new menu... anything. Register with us to make your next profitable event happen easily.',
    },
    {
        name: 'Your business',
        image: '/images/yoga_thankyou.jpg',
        bio: 'Got a great idea for a new tasting menu but don’t know if the risk is worth it? Want to put that gig on but not sure if there fans are up for it that night? We can sort you out.',
    },
    {
        name: 'Your venue',
        image: '/images/yoga_thankyou.jpg',
        bio: 'Looking to build revenue with the right partners at times when your space is un- or under- used? We can help you find those lost revenue streams.',
    },
]

function PartnersPage() {
    return (
        <VStack minH="100vh" spacing={8} pb="4" color="white" bg="plejGreen.500">
            <NavHeading menuColour="grey" headingText="Turbafy" />
            <VStack spacing={8} maxW="800px">
                <VStack px="4" mb="8" w="full" alignItems="start">
                    <Heading as="h2">Become a partner</Heading>
                    <Text>
                        We will help you to discover, pitch, de-risk, and price optimise any event
                        with your audience. No more risk, lost capital, sunk time. Just the right
                        amount of revenue to make *it* happen.
                    </Text>
                    <Button
                        as="a"
                        href="https://forms.gle/2hsUnUtqr35magPz8"
                        mt="4"
                        variant="white"
                    >
                        Register Interest
                    </Button>
                </VStack>
                {partnerDetails.map(({ name, image, bio }, idx) => (
                    <Partner name={name} image={image} bio={bio} reverse={idx % 2 === 0} />
                ))}
            </VStack>
        </VStack>
    )
}

export default PartnersPage
