import { Text, VStack } from '@chakra-ui/react'
import SectionTitle, { SectionTitleType } from '../../atoms/SectionTitle/SectionTitle.component'

type TextSectionProps = {
    title?: string
    titleType: SectionTitleType
    content: string[]
}

function TextSection({ title, titleType, content }: TextSectionProps) {
    return (
        <VStack w="full" alignItems="start" spacing="2">
            {title && <SectionTitle text={title} type={titleType} />}
            {content.map((paragraph) => (
                <Text>{paragraph}</Text>
            ))}
        </VStack>
    )
}

export default TextSection
