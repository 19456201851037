import { Button, Heading, HStack, Icon, IconButton, VStack } from '@chakra-ui/react'
import RequiredCreateEventInput from '@v1_molecules/RequiredCreateEventInput/RequiredCreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'

export type ProvidersFormDetailsValues = {
    name: string
    bio: string
}

export type ProvidersFormValues = {
    providers: ProvidersFormDetailsValues[]
}

export interface ProvidersFormProps extends CreateEventSectionProps {
    onChange: (details: ProvidersFormValues) => void
    onValidationChange: (isValid: boolean) => void
}

function ProvidersForm({ variant, onChange, onValidationChange }: ProvidersFormProps) {
    const [providers, setProviders] = useState<
        {
            name: { value: string; isValid: boolean }
            bio: { value: string; isValid: boolean }
            isDeleted: boolean
        }[]
    >([
        {
            name: { value: '', isValid: false },
            bio: { value: '', isValid: false },
            isDeleted: false,
        },
    ])

    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        onChange({
            providers: providers.map((provider) => ({
                name: provider.name.value,
                bio: provider.bio.value,
            })),
        })

        const newIsValid = providers.every(
            (provider) => provider.name.isValid && provider.bio.isValid,
        )

        if (newIsValid !== isValid) {
            onValidationChange(newIsValid)
            setIsValid(newIsValid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providers])

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Providers:
            </Heading>
            {providers.map((provider, idx) => (
                <VStack
                    mt={provider.isDeleted ? -4 : undefined}
                    w="full"
                    alignItems="start"
                    spacing={4}
                    display={provider.isDeleted ? 'none' : 'flex'}
                >
                    <HStack spacing={4}>
                        <Heading as="h4" fontSize="xl">
                            Provider{' '}
                            {idx -
                                providers.slice(0, idx).filter(({ isDeleted }) => isDeleted)
                                    .length +
                                1}
                        </Heading>
                        {providers.filter(({ isDeleted }) => !isDeleted).length > 1 ? (
                            <IconButton
                                icon={<MdDelete />}
                                aria-label="delete section"
                                minW={8}
                                w={8}
                                minH={8}
                                h={8}
                                p={0}
                                onClick={() => {
                                    const newProviders = _.cloneDeep(providers)
                                    newProviders[idx].isDeleted = true

                                    setProviders(newProviders)
                                }}
                            />
                        ) : null}
                    </HStack>
                    <RequiredCreateEventInput
                        label="Name:"
                        errorMessage="Name is required"
                        type="text"
                        parentFormVariant={variant}
                        onChange={(v) => {
                            const newProviders = _.cloneDeep(providers)
                            newProviders[idx].name.value = v

                            setProviders(newProviders)
                        }}
                        onValidationChange={(isNameValid) => {
                            const newProviders = _.cloneDeep(providers)
                            newProviders[idx].name.isValid = isNameValid

                            setProviders(newProviders)
                        }}
                    />
                    <RequiredCreateEventInput
                        label="Bio:"
                        errorMessage="Bio is required"
                        type="text"
                        parentFormVariant={variant}
                        onChange={(v) => {
                            const newProviders = _.cloneDeep(providers)
                            newProviders[idx].bio.value = v

                            setProviders(newProviders)
                        }}
                        onValidationChange={(isBioValid) => {
                            const newProviders = _.cloneDeep(providers)
                            newProviders[idx].bio.isValid = isBioValid

                            setProviders(newProviders)
                        }}
                    />
                </VStack>
            ))}
            <Button
                my="-4"
                variant="textButton"
                onClick={() =>
                    setProviders([
                        ...providers,
                        {
                            name: { value: '', isValid: false },
                            bio: { value: '', isValid: false },
                            isDeleted: false,
                        },
                    ])
                }
                fontSize="md"
            >
                <Icon as={MdAdd} boxSize={4} /> Add another provider
            </Button>
        </CreateEventSection>
    )
}

export default ProvidersForm
