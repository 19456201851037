import { AspectRatio, Box } from '@chakra-ui/react'

export interface ImageDetails {
    src?: string
}

function CheckoutImages({
    bigImage,
    mediumImage,
    smallImage,
}: {
    bigImage: ImageDetails
    mediumImage: ImageDetails
    smallImage: ImageDetails
}) {
    return (
        <AspectRatio w="full" ratio={343 / 164}>
            <Box display="flex" gap="2">
                <Box
                    h="full"
                    flexGrow="3"
                    backgroundImage={bigImage.src}
                    backgroundColor="plejGreen.500"
                    backgroundSize="cover"
                    backgroundPosition="center"
                    borderRadius="24px"
                />
                <Box
                    h="full"
                    flexGrow="2"
                    backgroundImage={mediumImage.src}
                    backgroundColor="plejGreen.500"
                    backgroundSize="cover"
                    backgroundPosition="center"
                    borderRadius="24px"
                />
                <Box
                    h="full"
                    flexGrow="1"
                    backgroundImage={smallImage.src}
                    backgroundColor="plejGreen.500"
                    backgroundSize="cover"
                    backgroundPosition="center"
                    borderRadius="24px"
                />
            </Box>
        </AspectRatio>
    )
}

export default CheckoutImages
