import { Button, Heading, Text, VStack } from '@chakra-ui/react'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'

function InvestorsPage() {
    return (
        <VStack minH="100vh" spacing={8} pb="4" color="white" bg="plejGreen.500">
            <NavHeading menuColour="grey" headingText="Turbafy" />
            <VStack px={4} alignItems="start" spacing={4} maxW="800px">
                <Heading as="h2">Investing</Heading>
                <Text>
                    We’re pre-seed right now and actively looking to raise from the right investors.
                    If that could be you, take a look at the pitch deck and speak with Sean.
                </Text>
                <Button as="a" download="/docs/Investing.pdf" mt="4" variant="white">
                    Download
                </Button>
            </VStack>
        </VStack>
    )
}

export default InvestorsPage
