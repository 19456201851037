import { Flex, Text } from '@chakra-ui/react'

type EventPlusDetailsSchpielTextProps = {
    text: string
    textColour: string
}

function EventPlusDetailsSchpielText({ text, textColour }: EventPlusDetailsSchpielTextProps) {
    return (
        <Flex alignSelf="stretch" flexDirection="column" flexShrink="0">
            <Text
                color={textColour}
                fontSize="24px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="130%"
            >
                {text}
            </Text>
        </Flex>
    )
}

export default EventPlusDetailsSchpielText
