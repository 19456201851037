import { ColorProps } from '@chakra-ui/react'
import { EventStatusV1 } from '@commonwealthventures/poc-readstore-v1-models'
import ProgressBar from '@v1_atoms/ProgressBar/ProgressBar.component'

function FundProgress({
    status,
    currentAttendees,
    requiredTotalOfPledgedAttendees,
    capacity,
    pledgeEndDate,
    ticketSalesEndDate,
    type = 'detailed',
    barColor,
    trackColor,
    textColor,
}: {
    status: EventStatusV1
    currentAttendees: number
    requiredTotalOfPledgedAttendees: number
    capacity: number
    pledgeEndDate: string
    ticketSalesEndDate: string
    type?: 'detailed' | 'summary'
    barColor: ColorProps['color']
    trackColor: ColorProps['color']
    textColor: ColorProps['color']
}) {
    let typeOfAttendance: string

    // eslint-disable-next-line default-case
    switch (status) {
        case EventStatusV1.FAILED_FUNDING:
            typeOfAttendance = 'pledged'
            break
        case EventStatusV1.REQUIRES_FUNDING:
            typeOfAttendance = 'pledged'
            break
        case EventStatusV1.REACHED_PLEDGE_GOAL:
        case EventStatusV1.SOLD_OUT:
        case EventStatusV1.TICKETS_OFF_SALE:
            typeOfAttendance = 'are going'
    }

    const progressLeftText = `${currentAttendees} ${typeOfAttendance}`

    let progressCentreText: string = ''

    if (status === EventStatusV1.FAILED_FUNDING || status === EventStatusV1.REQUIRES_FUNDING) {
        const howManyMoreRequired = requiredTotalOfPledgedAttendees - currentAttendees

        if (status === EventStatusV1.FAILED_FUNDING) {
            progressCentreText = `${howManyMoreRequired} were required`
        } else {
            progressCentreText = `${howManyMoreRequired} more required`
        }
    } else if (status === EventStatusV1.REACHED_PLEDGE_GOAL || status === EventStatusV1.SOLD_OUT) {
        const spotsLeft = capacity - currentAttendees

        progressCentreText = `${spotsLeft} spot${spotsLeft !== 1 ? 's' : ''} remaining`
    }

    let daysToGo = 0

    if (status === EventStatusV1.REQUIRES_FUNDING) {
        const timeDiff = new Date(pledgeEndDate).getTime() - new Date().getTime()

        daysToGo = Math.round(timeDiff / (1000 * 60 * 60 * 24))
    } else if (status === EventStatusV1.REACHED_PLEDGE_GOAL) {
        const timeDiff = new Date(ticketSalesEndDate).getTime() - new Date().getTime()

        daysToGo = Math.round(timeDiff / (1000 * 60 * 60 * 24))
    }

    let daysToGoText = `${daysToGo} days to go`

    if (status === EventStatusV1.SOLD_OUT) {
        daysToGoText = 'sold out'
    } else if (status === EventStatusV1.FAILED_FUNDING) {
        daysToGoText = 'deadline passed'
    } else if (status === EventStatusV1.TICKETS_OFF_SALE) {
        daysToGoText = 'tickets off sale'
    }

    return (
        <ProgressBar
            max={
                status === EventStatusV1.REQUIRES_FUNDING
                    ? requiredTotalOfPledgedAttendees
                    : capacity
            }
            min={0}
            value={currentAttendees}
            leftText={progressLeftText}
            centreText={type === 'detailed' ? progressCentreText : undefined}
            rightText={daysToGoText}
            textColor={textColor}
            barColor={barColor}
            trackColor={trackColor}
        />
    )
}

export default FundProgress
