import { Container, VStack } from '@chakra-ui/react'
import { EventStatusV1 } from '@commonwealthventures/poc-readstore-v1-models'
import formatDate from '@utils/formatDate'
import DetailsTagline from '@v1_atoms/DetailsTagline/DetailsTagline.component'
import EventPlusDetailsSchpielText from '@v1_atoms/EventPlusDetailsSchpielText/EventPlusDetailsSchpielText.component'
import EventTitle from '@v1_atoms/EventTitle/EventTitle.component'
import FundImage from '@v1_atoms/FundImage/FundImage.component'
import SectionTitle, { SectionTitleType } from '@v1_atoms/SectionTitle/SectionTitle.component'
import EventQueryingPage, { EventWithImages } from '@v1_hocs/EventQueryingPage.component'
import ButtonSection from '@v1_molecules/ButtonSection/ButtonSection.component'
import DateSection from '@v1_molecules/DateSection/DateSection.component'
import FundProgress from '@v1_molecules/FundProgress/FundProgress.component'
import IncludedSection from '@v1_molecules/IncludedSection/IncludedSection.component'
import LocationSection from '@v1_molecules/LocationSection/LocationSection.component'
import OrganiserSection from '@v1_molecules/OrganiserSection/OrganiserSection.component'
import TextSection from '@v1_molecules/TextSection/TextSection.component'
import TicketOptionsSection from '@v1_molecules/TicketOptionsSection/TicketOptionsSection.component'
import TeamMembers from '@v1_organisms/TeamMembers/TeamMembers.component'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { MdInfoOutline, MdClose } from 'react-icons/md'

function EventPageContents({ data }: { data: EventWithImages }) {
    const [initialLoad, setInitialLoad] = useState(true)
    const [showDetails, setShowDetails] = useState(false)

    const {
        title,
        date,
        summary,
        venue,
        images,
        ticketOptions,
        organiser,
        detailedOverview,
        whatsIncluded,
        whatsNotIncluded,
        providers,
        additionalInformation,
        status,
        currentAttendees,
        requiredTotalOfPledgedAttendees,
        capacity,
        pledgeEndDate,
        ticketSalesEndDate,
    } = data!

    let disabled = false
    let buttonText: string

    // eslint-disable-next-line default-case
    switch (status) {
        case EventStatusV1.FAILED_FUNDING:
            disabled = true
        // eslint-disable-next-line no-fallthrough
        case EventStatusV1.REQUIRES_FUNDING:
            buttonText = 'Pledge to crowdfund'
            break
        case EventStatusV1.SOLD_OUT:
        case EventStatusV1.TICKETS_OFF_SALE:
            disabled = true
        // eslint-disable-next-line no-fallthrough
        case EventStatusV1.REACHED_PLEDGE_GOAL:
            buttonText = 'Buy ticket'
    }

    const buttonSection = (
        <ButtonSection
            mainButton={{
                text: buttonText,
                href: `${
                    window.location.href.endsWith('/')
                        ? window.location.href.slice(0, -1)
                        : window.location.href
                }/confirm`,
                disabled,
            }}
            iconButton={{
                icon: showDetails ? <MdClose /> : <MdInfoOutline />,
                text: 'show more event details',
                onClick: () => {
                    setShowDetails(!showDetails)
                    setInitialLoad(false)
                },
            }}
        />
    )

    return !showDetails ? (
        <motion.div
            key="hello"
            exit={{ opacity: 0 }}
            initial={{ opacity: initialLoad ? 1 : 0 }}
            animate={{ opacity: 1 }}
        >
            <Container p="0">
                <FundImage src={images.primary} alt="Primary image of event" />
                <VStack
                    justifyContent="end"
                    height="calc(100vh - 16px)"
                    p="4"
                    pb="6"
                    spacing="6"
                    w="full"
                >
                    <VStack spacing="1" alignItems="start" w="full">
                        <EventTitle text={title} />
                        <DetailsTagline
                            dateText={formatDate(date)}
                            location={venue.city}
                            generalLocation={venue.country}
                        />
                    </VStack>
                    <FundProgress
                        status={status}
                        capacity={capacity}
                        currentAttendees={currentAttendees}
                        requiredTotalOfPledgedAttendees={requiredTotalOfPledgedAttendees}
                        pledgeEndDate={pledgeEndDate}
                        ticketSalesEndDate={ticketSalesEndDate}
                        barColor="plejGreen.500"
                        trackColor="plejGrey.300"
                        textColor="white"
                    />
                    {buttonSection}
                </VStack>
            </Container>
        </motion.div>
    ) : (
        <motion.div
            key="world"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            <Container p="4" position="relative">
                <Container zIndex={1} position="fixed" left="0" bottom="0" px="4" pb="10">
                    {buttonSection}
                </Container>
                <VStack alignItems="start" spacing="4" p="0" pb="120px">
                    <EventPlusDetailsSchpielText textColour="black" text={summary} />
                    <DateSection dates={[date]} />
                    <LocationSection
                        address={[
                            ...venue.streetAddress,
                            venue.city,
                            venue.postCode,
                            venue.country,
                        ]}
                    />
                    <TicketOptionsSection optionsType="Ticket" eventOptions={ticketOptions} />
                    <OrganiserSection name={organiser.name} src={images.organiser} />
                    <TextSection
                        title="Overview"
                        titleType={SectionTitleType.SECTION}
                        content={[detailedOverview]}
                    />
                    <IncludedSection type="included" list={whatsIncluded} />
                    <IncludedSection type="excluded" list={whatsNotIncluded} />
                    {additionalInformation &&
                        additionalInformation.map((a) => (
                            <VStack w="full" alignItems="start" spacing="4">
                                <SectionTitle type={SectionTitleType.SECTION} text={a.title} />
                                {a.subSections.map((s) => {
                                    const content = s.text

                                    return (
                                        <TextSection
                                            title={s.sectionTitle}
                                            titleType={SectionTitleType.KEY_DETAIL}
                                            content={content}
                                        />
                                    )
                                })}
                            </VStack>
                        ))}
                    <TeamMembers teamMembers={providers} images={images.providers} />
                </VStack>
            </Container>
        </motion.div>
    )
}

export default function EventPage() {
    return (
        <EventQueryingPage>
            {(data: EventWithImages) => <EventPageContents data={data} />}
        </EventQueryingPage>
    )
}
