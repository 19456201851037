import { VStack } from '@chakra-ui/react'
import { MdOutlineLocationOn } from 'react-icons/md'
import IconText from '../../atoms/IconText/IconText.component'
import SectionTitle, { SectionTitleType } from '../../atoms/SectionTitle/SectionTitle.component'

function LocationSection({ address }: { address: string[] }) {
    return (
        <VStack w="full" alignItems="start" spacing="2">
            <SectionTitle text="Location" type={SectionTitleType.KEY_DETAIL} />
            <IconText text={address.join(', ')} icon={MdOutlineLocationOn} />
        </VStack>
    )
}

export default LocationSection
