import { VStack } from '@chakra-ui/react'
import { MdCheckCircleOutline, MdOutlineCancel } from 'react-icons/md'
import IconText from '../../atoms/IconText/IconText.component'
import SectionTitle, { SectionTitleType } from '../../atoms/SectionTitle/SectionTitle.component'

const icons = {
    included: MdCheckCircleOutline,
    excluded: MdOutlineCancel,
}

function IncludedSection({ list, type }: { list: string[]; type: keyof typeof icons }) {
    return (
        <VStack w="full" alignItems="start" spacing="2">
            <SectionTitle
                text={`What's${type === 'excluded' ? ' not' : ''} included`}
                type={SectionTitleType.SECTION}
            />
            <VStack alignItems="start" pt="2" spacing="4">
                {list.map((item) => (
                    <IconText text={item} icon={icons[type]} />
                ))}
            </VStack>
        </VStack>
    )
}

export default IncludedSection
