import { Button, Heading, Text, VStack } from '@chakra-ui/react'
import { AdminCreateFundCurrency } from '@commonwealthventures/poc-admin-api-models'
import { CurrencyV1 } from '@commonwealthventures/poc-readstore-v1-models'
import createFund from '@services/createFund.service'
import RequiredCreateEventInput from '@v1_molecules/RequiredCreateEventInput/RequiredCreateEventInput.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import AdditionalInformationForm, {
    AdditionalInformationFormValues,
} from '@v1_templates/AdditionalInformationForm/AdditionalInformationForm.component'
import EventDetailsForm, {
    EventDetailsFormValues,
} from '@v1_templates/EventDetailsForm/EventDetailsForm.component'
import FundDetailsForm, {
    FundDetailsFormValues,
} from '@v1_templates/FundDetailsForm/FundDetailsForm.component'
import OrganiserDetailsForm, {
    OrganiserDetailsFormValues,
} from '@v1_templates/OrganiserDetailsForm/OrganiserDetailsForm.component'
import ProvidersForm, {
    ProvidersFormValues,
} from '@v1_templates/ProvidersForm/ProvidersForm.component'
import TicketDetailsForm, {
    TicketDetailsFormValues,
} from '@v1_templates/TicketDetailsForm/TicketDetailsForm.component'
import VenueDetailsForm, {
    VenueDetailsFormValues,
} from '@v1_templates/VenueDetailsForm/VenueDetailsForm.component'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function CreateEventPage() {
    const navigate = useNavigate()

    const [adminApiKey, setAdminApiKey] = useState({
        value: process.env.REACT_APP_ADMIN_API_KEY ?? '',
        isValid: !!process.env.REACT_APP_ADMIN_API_KEY,
    })

    const [organiserDetails, setOrganiserDetails] = useState<OrganiserDetailsFormValues>({
        id: '',
        name: '',
    })
    const [organiserDetailsIsValid, setOrganiserDetailsIsValid] = useState(false)

    const [fundDetails, setFundDetails] = useState<FundDetailsFormValues>({
        fundingDeadline: '',
        traditionalPurchaseDeadline: '',
        fundingThreshold: -1,
        maxPlaces: -1,
    })
    const [fundDetailsIsValid, setFundDetailsIsValid] = useState(false)

    const [eventDetails, setEventDetails] = useState<EventDetailsFormValues>({
        title: '',
        summary: '',
        detailedOverview: '',
        startDate: '',
        endDate: '',
        whatsIncluded: [''],
        whatsNotIncluded: [''],
    })
    const [eventDetailsIsValid, setEventDetailsIsValid] = useState(false)

    const [additionalInformation, setAdditionalInformation] =
        useState<AdditionalInformationFormValues>({
            sections: [
                {
                    title: '',
                    subSections: [
                        {
                            sectionTitle: '',
                            text: [''],
                        },
                    ],
                },
            ],
        })
    const [additionalInformationIsValid, setAdditionalInformationIsValid] = useState(false)

    const [venueDetails, setVenueDetails] = useState<VenueDetailsFormValues>({
        addressLines: [''],
        city: '',
        postcode: '',
        country: '',
    })
    const [venueDetailsIsValid, setVenueDetailsIsValid] = useState(false)

    const [providers, setProviders] = useState<ProvidersFormValues>({
        providers: [
            {
                name: '',
                bio: '',
            },
        ],
    })
    const [providersIsValid, setProvidersIsValid] = useState(false)

    const [ticketDetails, setTicketDetails] = useState<TicketDetailsFormValues>({
        ticketOptions: [
            {
                name: '',
                priceInPence: 0,
            },
        ],
        currency: CurrencyV1.GBP,
    })
    const [ticketDetailsIsValid, setTicketDetailsIsValid] = useState(false)

    const [formIsValid, setFormIsValid] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showError, setShowError] = useState<string | undefined>()

    useEffect(() => {
        setFormIsValid(
            adminApiKey.isValid &&
                organiserDetailsIsValid &&
                fundDetailsIsValid &&
                eventDetailsIsValid &&
                additionalInformationIsValid &&
                venueDetailsIsValid &&
                providersIsValid &&
                ticketDetailsIsValid,
        )
    }, [
        adminApiKey,
        organiserDetailsIsValid,
        fundDetailsIsValid,
        eventDetailsIsValid,
        additionalInformationIsValid,
        venueDetailsIsValid,
        providersIsValid,
        ticketDetailsIsValid,
    ])

    return (
        <VStack>
            <NavHeading menuColour="green" headingText="Turbafy" />
            <VStack spacing={8} w="full">
                <VStack
                    spacing={8}
                    w="full"
                    maxW="800px"
                    alignItems="start"
                    textColor="plejGreen.500"
                >
                    <Heading as="h2">Create new fund/event</Heading>
                    <RequiredCreateEventInput
                        label="Admin API key:"
                        errorMessage="Admin API key is required"
                        onChange={(v) => setAdminApiKey({ value: v, isValid: adminApiKey.isValid })}
                        onValidationChange={(isValid) =>
                            setAdminApiKey({ value: adminApiKey.value, isValid })
                        }
                        parentFormVariant="white"
                        type="text"
                        initialValue={process.env.REACT_APP_ADMIN_API_KEY}
                    />
                    <OrganiserDetailsForm
                        variant="white"
                        onChange={(v) => setOrganiserDetails(v)}
                        onValidationChange={(isValid) => setOrganiserDetailsIsValid(isValid)}
                    />
                </VStack>
                <VStack bg="plejGreen.500" alignItems="center" w="full" pt={8} pb={8}>
                    <FundDetailsForm
                        variant="green"
                        onChange={(v) => setFundDetails(v)}
                        onValidationChange={(isValid) => setFundDetailsIsValid(isValid)}
                    />
                </VStack>
                <VStack alignItems="center" w="full">
                    <EventDetailsForm
                        variant="white"
                        onChange={(v) => setEventDetails(v)}
                        onValidationChange={(isValid) => setEventDetailsIsValid(isValid)}
                    />
                </VStack>
                <VStack
                    bg="plejGreen.500"
                    textColor="white"
                    alignItems="center"
                    w="full"
                    pt={8}
                    pb={8}
                >
                    <AdditionalInformationForm
                        variant="green"
                        onChange={(v) => setAdditionalInformation(v)}
                        onValidationChange={(isValid) => setAdditionalInformationIsValid(isValid)}
                    />
                </VStack>
                <VStack
                    bg="white"
                    textColor="plejGreen.500"
                    alignItems="center"
                    w="full"
                    pt={8}
                    pb={8}
                >
                    <VenueDetailsForm
                        variant="white"
                        onChange={(v) => setVenueDetails(v)}
                        onValidationChange={(isValid) => setVenueDetailsIsValid(isValid)}
                    />
                </VStack>
                <VStack
                    bg="plejGreen.500"
                    textColor="white"
                    alignItems="center"
                    w="full"
                    pt={8}
                    pb={8}
                >
                    <ProvidersForm
                        variant="green"
                        onChange={(v) => setProviders(v)}
                        onValidationChange={(isValid) => setProvidersIsValid(isValid)}
                    />
                </VStack>
                <VStack
                    bg="white"
                    textColor="plejGreen.500"
                    alignItems="center"
                    w="full"
                    pt={8}
                    pb={8}
                >
                    <TicketDetailsForm
                        variant="white"
                        onChange={(v) => setTicketDetails(v)}
                        onValidationChange={(isValid) => setTicketDetailsIsValid(isValid)}
                    />
                </VStack>
            </VStack>
            <Button
                maxW="800px"
                mt={6}
                mb={showError ? undefined : 6}
                isLoading={isSubmitting}
                isDisabled={!formIsValid}
                onClick={async () => {
                    setShowError(undefined)
                    setIsSubmitting(true)

                    const {
                        success,
                        value: id,
                        error,
                    } = await createFund(
                        {
                            organiserId: organiserDetails.id,
                            fundingDeadline: new Date(fundDetails.fundingDeadline).toISOString(),
                            fundingThreshold: fundDetails.fundingThreshold,
                            traditionalPurchaseDeadline: new Date(
                                fundDetails.traditionalPurchaseDeadline,
                            ).toISOString(),
                            maximumPlacesAvailable: fundDetails.maxPlaces,
                            eventDetails: {
                                title: eventDetails.title,
                                summary: eventDetails.summary,
                                detailedOverview: eventDetails.detailedOverview,
                                date: {
                                    startDate: new Date(eventDetails.startDate).toISOString(),
                                    endDate: new Date(eventDetails.endDate).toISOString(),
                                },
                                venue: {
                                    streetAddress: venueDetails.addressLines,
                                    city: venueDetails.city,
                                    postCode: venueDetails.postcode,
                                    country: venueDetails.country,
                                },
                                ticketOptions: ticketDetails.ticketOptions.map((option) => ({
                                    name: option.name,
                                    price:
                                        Math.round(
                                            (option.priceInPence / 100 + Number.EPSILON) * 100,
                                        ) / 100,
                                    currency:
                                        ticketDetails.currency as unknown as AdminCreateFundCurrency,
                                })),
                                organiser: {
                                    name: organiserDetails.name,
                                },
                                providers: providers.providers,
                                additionalInformation: additionalInformation.sections,
                                whatsIncluded: eventDetails.whatsIncluded,
                                whatsNotIncluded: eventDetails.whatsNotIncluded,
                            },
                        },
                        adminApiKey.value,
                    )

                    setIsSubmitting(false)

                    if (!success) {
                        setShowError(
                            `Something went wrong creating your event. ${error}. Try again`,
                        )
                        return
                    }

                    navigate(`/admin/create-event/${id}/images`)
                }}
            >
                Create
            </Button>
            {showError ? (
                <Text mb={6} textColor="red">
                    {showError}
                </Text>
            ) : null}
        </VStack>
    )
}

export default CreateEventPage
