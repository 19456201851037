import { FormControl, FormLabel, Heading } from '@chakra-ui/react'
import MultiSectionInput from '@v1_molecules/MultiSectionInput/MultiSectionInput.component'
import RequiredCreateEventInput from '@v1_molecules/RequiredCreateEventInput/RequiredCreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import { useEffect, useState } from 'react'

export type EventDetailsFormValues = {
    title: string
    summary: string
    detailedOverview: string
    startDate: string
    endDate: string
    whatsIncluded: string[]
    whatsNotIncluded: string[]
}

export interface EventDetailsFormProps extends CreateEventSectionProps {
    onChange: (details: EventDetailsFormValues) => void
    onValidationChange: (isValid: boolean) => void
}

function EventDetailsForm({ variant, onChange, onValidationChange }: EventDetailsFormProps) {
    const [title, setTitle] = useState({ value: '', isValid: false })
    const [summary, setSummary] = useState({ value: '', isValid: false })
    const [detailedOverview, setDetailedOverview] = useState({ value: '', isValid: false })
    const [startDate, setStartDate] = useState({ value: '', isValid: false })
    const [endDate, setEndDate] = useState({ value: '', isValid: false })
    const [whatsIncluded, setWhatsIncluded] = useState({
        value: [''],
        isValid: false,
    })
    const [whatsNotIncluded, setWhatsNotIncluded] = useState({
        value: [''],
        isValid: false,
    })

    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        onChange({
            title: title.value,
            summary: summary.value,
            detailedOverview: detailedOverview.value,
            startDate: startDate.value,
            endDate: endDate.value,
            whatsIncluded: whatsIncluded.value,
            whatsNotIncluded: whatsNotIncluded.value,
        })

        const newIsValid =
            title.isValid &&
            summary.isValid &&
            detailedOverview.isValid &&
            startDate.isValid &&
            endDate.isValid &&
            whatsIncluded.isValid &&
            whatsNotIncluded.isValid

        if (newIsValid !== isValid) {
            onValidationChange(newIsValid)
            setIsValid(newIsValid)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, summary, detailedOverview, startDate, endDate, whatsIncluded, whatsNotIncluded])

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Event details
            </Heading>
            <RequiredCreateEventInput
                label="Title:"
                errorMessage="Event title is required"
                onChange={(v) => setTitle({ value: v, isValid: title.isValid })}
                onValidationChange={(isIdValid) =>
                    setTitle({ value: title.value, isValid: isIdValid })
                }
                parentFormVariant={variant}
                type="text"
            />
            <RequiredCreateEventInput
                label="Summary:"
                errorMessage="Event summary is required"
                onChange={(v) => setSummary({ value: v, isValid: summary.isValid })}
                onValidationChange={(isIdValid) =>
                    setSummary({ value: summary.value, isValid: isIdValid })
                }
                parentFormVariant={variant}
                type="text"
            />
            <RequiredCreateEventInput
                label="Detailed overview:"
                errorMessage="Event overview is required"
                onChange={(v) =>
                    setDetailedOverview({ value: v, isValid: detailedOverview.isValid })
                }
                onValidationChange={(isIdValid) =>
                    setDetailedOverview({ value: detailedOverview.value, isValid: isIdValid })
                }
                parentFormVariant={variant}
                type="textarea"
            />
            <RequiredCreateEventInput
                label="Start date:"
                errorMessage="Event start date is required"
                onChange={(v) => setStartDate({ value: v, isValid: startDate.isValid })}
                onValidationChange={(isIdValid) =>
                    setStartDate({ value: startDate.value, isValid: isIdValid })
                }
                parentFormVariant={variant}
                type="datetime-local"
            />
            <RequiredCreateEventInput
                label="End date:"
                errorMessage="Event end date is required"
                onChange={(v) => setEndDate({ value: v, isValid: endDate.isValid })}
                onValidationChange={(isIdValid) =>
                    setEndDate({ value: endDate.value, isValid: isIdValid })
                }
                parentFormVariant={variant}
                type="datetime-local"
            />
            <FormControl>
                <FormLabel fontSize="lg">What&apos;s included:</FormLabel>
                <MultiSectionInput
                    parentFormVariant={variant}
                    type="textarea"
                    addButtonText="Add another included item"
                    errorMessage="All whats included fields must have a value"
                    onChange={(values) =>
                        setWhatsIncluded({
                            value: values,
                            isValid: whatsIncluded.isValid,
                        })
                    }
                    onValidationChange={(whatsIncludedIsValid) => {
                        setWhatsIncluded({
                            value: whatsIncluded.value,
                            isValid: whatsIncludedIsValid,
                        })
                    }}
                />
            </FormControl>
            <FormControl>
                <FormLabel fontSize="lg">What&apos;s not included:</FormLabel>
                <MultiSectionInput
                    parentFormVariant={variant}
                    type="textarea"
                    addButtonText="Add another excluded item"
                    errorMessage="All whats excluded fields must have a value"
                    onChange={(values) =>
                        setWhatsNotIncluded({
                            value: values,
                            isValid: whatsNotIncluded.isValid,
                        })
                    }
                    onValidationChange={(whatsNotIncludedIsValid) => {
                        setWhatsNotIncluded({
                            value: whatsNotIncluded.value,
                            isValid: whatsNotIncludedIsValid,
                        })
                    }}
                />
            </FormControl>
        </CreateEventSection>
    )
}

export default EventDetailsForm
