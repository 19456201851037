import { Box, ColorProps, HStack, Text, VStack } from '@chakra-ui/react'

type ProgressBarProps = {
    max: number
    min?: number
    value: number
    leftText: string
    rightText: string
    centreText?: string
    barColor: ColorProps['color']
    trackColor: ColorProps['color']
    textColor: ColorProps['color']
}

function ProgressBar({
    max,
    min = 0,
    value,
    leftText,
    centreText,
    rightText,
    barColor,
    trackColor,
    textColor,
}: ProgressBarProps) {
    const percentage = (value / (max - min)) * 100

    return (
        <VStack spacing="2" w="full">
            <Box height="6px" width="full" backgroundColor={trackColor} borderRadius="3px">
                <Box
                    height="full"
                    width={`${percentage}%`}
                    backgroundColor={barColor}
                    borderRadius="inherit"
                />
            </Box>
            <HStack
                color={textColor}
                justifyContent="space-between"
                alignItems="flex-start"
                width="100%"
            >
                <Text maxWidth={centreText ? '33%' : '50%'}>{leftText}</Text>
                {centreText && <Text maxWidth="33%">{centreText}</Text>}
                <Text maxWidth={centreText ? '33%' : '50%'}>{rightText}</Text>
            </HStack>
        </VStack>
    )
}

export default ProgressBar
