import { Spinner, Text, VStack } from '@chakra-ui/react'
import { GetEventImageMapResponse } from '@commonwealthventures/image-service-api-models'
import { EventV1 } from '@commonwealthventures/poc-readstore-v1-models'
import getImageMap from '@services/getImageMap.service'
import axios from 'axios'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

export type EventWithImages = Omit<EventV1, 'images'> & { images: GetEventImageMapResponse }

export default function EventQueryingPage({
    children,
}: {
    children: (data: EventWithImages) => JSX.Element
}) {
    const { id } = useParams()

    const {
        data: eventData,
        error: eventError,
        isLoading: eventIsLoading,
    } = useQuery<EventV1>({
        queryKey: ['EVENT_V1', id],
        queryFn: async () => {
            const result = await axios.get(
                `${process.env.REACT_APP_DATASTORE_API_URL}/v1/events/${id}`,
                {
                    headers: {
                        'x-api-key': process.env.REACT_APP_READSTORE_API_KEY!,
                    },
                },
            )

            return result.data
        },
        retry: 3,
        retryDelay: 500,
    })

    const {
        data: imagesData,
        error: imagesError,
        isLoading: imagesIsLoading,
    } = useQuery<GetEventImageMapResponse>({
        queryKey: ['IMAGES', id],
        queryFn: async () => {
            return getImageMap(id!)
        },
        retry: 3,
        retryDelay: 500,
    })

    if (eventError || imagesError) {
        return (
            <VStack
                minW="100vw"
                h="100vh"
                bg="plejGreen.500"
                justifyContent="center"
                alignItems="center"
                spacing="4"
                color="white"
            >
                <Text>Sorry. An error occurred loading this event.</Text>
            </VStack>
        )
    }

    if (eventIsLoading || imagesIsLoading) {
        return (
            <VStack
                minW="100vw"
                h="100vh"
                bg="plejGreen.500"
                justifyContent="center"
                alignItems="center"
                spacing="4"
                color="white"
            >
                <Spinner size="xl" thickness="4px" speed="0.75s" />
                <Text fontSize="xl">Loading...</Text>
            </VStack>
        )
    }

    const data: EventWithImages = { ...eventData!, images: imagesData! }

    return children(data)
}
