import {
    Button,
    FormControl,
    FormErrorMessage,
    HStack,
    Icon,
    IconButton,
    VStack,
} from '@chakra-ui/react'
import { CreateEventInputProps } from '@v1_atoms/CreateEventInput/CreateEventInput.component'
import RequiredCreateEventInputField from '@v1_atoms/RequiredCreateEventInputField/RequiredCreateEventInputField.component'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'

export interface MultiSectionInputProps {
    parentFormVariant: CreateEventInputProps['parentFormVariant']
    type: Exclude<CreateEventInputProps['type'], 'select'>
    addButtonText: string
    errorMessage: string
    onChange: (v: string[]) => void
    onValidationChange: (isValid: boolean) => void
}

const deletionMatcher = '%%DELETED%%'

function MultiSectionInput({
    parentFormVariant,
    type,
    addButtonText,
    errorMessage,
    onChange,
    onValidationChange,
}: MultiSectionInputProps) {
    const [values, setValues] = useState([''])
    const [isValids, setIsValids] = useState([false])
    const [showErrors, setShowErrors] = useState([false])

    const [overallIsValid, setOverallIsValid] = useState(false)

    useEffect(() => {
        onChange(values.filter((v) => v !== deletionMatcher))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    useEffect(() => {
        const newIsValid = isValids.every((isValid) => isValid)

        if (newIsValid !== overallIsValid) {
            setOverallIsValid(newIsValid)
            onValidationChange(newIsValid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValids])

    return (
        <>
            <VStack spacing={4}>
                {values.map((value, idx) => {
                    const deletedValue = value === deletionMatcher

                    return (
                        <FormControl marginTop={deletedValue ? -4 : 0} isInvalid={showErrors[idx]}>
                            <HStack spacing={2}>
                                <RequiredCreateEventInputField
                                    onChange={(v) => {
                                        const newValue = _.clone(values)
                                        newValue[idx] = v

                                        setValues(newValue)
                                    }}
                                    onValidationChange={(isValid) => {
                                        const newIsValids = _.cloneDeep(isValids)
                                        newIsValids[idx] = isValid

                                        setIsValids(newIsValids)
                                    }}
                                    parentFormVariant={parentFormVariant}
                                    type={type}
                                    onShowErrorChange={(showError) => {
                                        const newShowErrors = _.cloneDeep(showErrors)
                                        newShowErrors[idx] = showError

                                        setShowErrors(newShowErrors)
                                    }}
                                    deleted={deletedValue}
                                />
                                {values.filter((v) => v !== deletionMatcher).length !== 1 &&
                                !deletedValue ? (
                                    <IconButton
                                        w={4}
                                        bg={
                                            parentFormVariant === 'green'
                                                ? 'white'
                                                : 'plejGreen.500'
                                        }
                                        color={
                                            parentFormVariant === 'green'
                                                ? 'plejGreen.500'
                                                : 'white'
                                        }
                                        _hover={{
                                            bg:
                                                parentFormVariant === 'green'
                                                    ? 'plejGrey.500'
                                                    : 'plejGreen.700',
                                        }}
                                        aria-label="remove item"
                                        icon={<MdDelete />}
                                        onClick={() => {
                                            const newValues = _.clone(values)
                                            newValues[idx] = deletionMatcher

                                            setValues(newValues)

                                            const newIsValids = _.clone(isValids)
                                            newIsValids[idx] = true

                                            setIsValids(newIsValids)

                                            const newShowErrors = _.clone(showErrors)
                                            newShowErrors[idx] = false

                                            setShowErrors(newShowErrors)
                                        }}
                                    />
                                ) : null}
                            </HStack>
                            <FormErrorMessage>{errorMessage}</FormErrorMessage>
                        </FormControl>
                    )
                })}
            </VStack>
            <Button
                variant="textButton"
                onClick={() => {
                    setValues([...values, ''])
                    setIsValids([...isValids, false])
                    setShowErrors([...showErrors, false])
                }}
                fontSize="md"
                mb="-4"
            >
                <Icon as={MdAdd} boxSize={4} /> {addButtonText}
            </Button>
        </>
    )
}

export default MultiSectionInput
