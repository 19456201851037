import { ColorProps, Heading } from '@chakra-ui/react'

function DetailsTagline({
    dateText,
    location,
    generalLocation,
    color = 'white',
}: {
    dateText: string
    location: string
    generalLocation: string
    color?: ColorProps['color']
}) {
    return (
        <Heading as="h2" fontSize="md" color={color} fontWeight="400">
            {dateText} &#8226; {location}, {generalLocation}
        </Heading>
    )
}

export default DetailsTagline
