import {
    Button,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Icon,
    IconButton,
    Radio,
    RadioGroup,
    VStack,
} from '@chakra-ui/react'
import { CurrencyV1 } from '@commonwealthventures/poc-readstore-v1-models'
import RequiredCreateEventInput from '@v1_molecules/RequiredCreateEventInput/RequiredCreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'

export type TicketDetailsFormOptionsValues = {
    name: string
    priceInPence: number
}

export type TicketDetailsFormValues = {
    ticketOptions: TicketDetailsFormOptionsValues[]
    currency: CurrencyV1
}

export interface TicketDetailsFormProps extends CreateEventSectionProps {
    onChange: (details: TicketDetailsFormValues) => void
    onValidationChange: (isValid: boolean) => void
}

function TicketDetailsForm({ variant, onChange, onValidationChange }: TicketDetailsFormProps) {
    const [ticketOptions, setTicketOptions] = useState<
        {
            name: { value: string; isValid: boolean }
            priceInPence: { value: number; isValid: boolean }
            isDeleted: boolean
        }[]
    >([
        {
            name: { value: '', isValid: false },
            priceInPence: { value: 0, isValid: false },
            isDeleted: false,
        },
    ])
    const [currency, setCurrency] = useState(CurrencyV1.GBP)

    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        onChange({
            ticketOptions: ticketOptions.map((ticketOption) => ({
                name: ticketOption.name.value,
                priceInPence: ticketOption.priceInPence.value,
            })),
            currency,
        })

        const newIsValid = ticketOptions.every(
            (ticketOption) => ticketOption.name.isValid && ticketOption.priceInPence.isValid,
        )

        if (newIsValid !== isValid) {
            onValidationChange(newIsValid)
            setIsValid(newIsValid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency, ticketOptions])

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Ticket details:
            </Heading>
            <FormControl>
                <FormLabel>Currency</FormLabel>
                <RadioGroup onChange={(e) => setCurrency(e as CurrencyV1)} value={currency}>
                    <VStack alignItems="start">
                        {Object.values(CurrencyV1).map((v) => (
                            <Radio value={v}>{v}</Radio>
                        ))}
                    </VStack>
                </RadioGroup>
            </FormControl>
            {ticketOptions.map((option, idx) => (
                <VStack
                    mt={option.isDeleted ? -4 : undefined}
                    w="full"
                    alignItems="start"
                    spacing={4}
                    display={option.isDeleted ? 'none' : 'flex'}
                >
                    <HStack spacing={4}>
                        <Heading as="h4" fontSize="xl">
                            Ticket option{' '}
                            {idx -
                                ticketOptions.slice(0, idx).filter(({ isDeleted }) => isDeleted)
                                    .length +
                                1}
                        </Heading>
                        {ticketOptions.filter(({ isDeleted }) => !isDeleted).length > 1 ? (
                            <IconButton
                                icon={<MdDelete />}
                                aria-label="delete section"
                                minW={8}
                                w={8}
                                minH={8}
                                h={8}
                                p={0}
                                onClick={() => {
                                    const newTicketOptions = _.cloneDeep(ticketOptions)
                                    newTicketOptions[idx].isDeleted = true

                                    setTicketOptions(newTicketOptions)
                                }}
                            />
                        ) : null}
                    </HStack>
                    <RequiredCreateEventInput
                        label="Name:"
                        errorMessage="Ticket name is required"
                        type="text"
                        parentFormVariant={variant}
                        onChange={(v) => {
                            const newTicketOptions = _.cloneDeep(ticketOptions)
                            newTicketOptions[idx].name.value = v

                            setTicketOptions(newTicketOptions)
                        }}
                        onValidationChange={(isNameValid) => {
                            const newTicketOptions = _.cloneDeep(ticketOptions)
                            newTicketOptions[idx].name.isValid = isNameValid

                            setTicketOptions(newTicketOptions)
                        }}
                    />
                    <RequiredCreateEventInput
                        label="Price in pence:"
                        errorMessage="Ticket price is required"
                        type="number"
                        precision={0}
                        parentFormVariant={variant}
                        onChange={(v) => {
                            const newTicketOptions = _.cloneDeep(ticketOptions)
                            newTicketOptions[idx].priceInPence.value = Number.parseInt(v, 10)

                            setTicketOptions(newTicketOptions)
                        }}
                        onValidationChange={(isPriceValid) => {
                            const newTicketOptions = _.cloneDeep(ticketOptions)
                            newTicketOptions[idx].priceInPence.isValid = isPriceValid

                            setTicketOptions(newTicketOptions)
                        }}
                    />
                </VStack>
            ))}
            <Button
                my="-4"
                variant="textButton"
                onClick={() =>
                    setTicketOptions([
                        ...ticketOptions,
                        {
                            name: { value: '', isValid: false },
                            priceInPence: { value: 0, isValid: false },
                            isDeleted: false,
                        },
                    ])
                }
                fontSize="md"
            >
                <Icon as={MdAdd} boxSize={4} /> Add another ticket option
            </Button>
        </CreateEventSection>
    )
}

export default TicketDetailsForm
