import Months from '../constants/Months'

export default function formatDate({ startDate, endDate }: { startDate: string; endDate: string }) {
    const startDateAsDate = new Date(startDate)
    const endDateAsDate = new Date(endDate)

    let text: string

    if (startDate.split('T')[0] === endDate.split('T')[0]) {
        text = `${startDateAsDate.getDate()} ${
            Months[startDateAsDate.getMonth()]
        } ${startDateAsDate.getFullYear()}`
    } else {
        const startDateMonth =
            startDateAsDate.getMonth() === endDateAsDate.getMonth()
                ? ''
                : ` ${Months[startDateAsDate.getMonth()]}`

        const startDateYear =
            startDateAsDate.getFullYear() === endDateAsDate.getFullYear()
                ? ''
                : ` ${startDateAsDate.getFullYear()}`

        text = `${startDateAsDate.getDate()}${startDateMonth}${startDateYear} - ${endDateAsDate.getDate()} ${
            Months[endDateAsDate.getMonth()]
        } ${endDateAsDate.getFullYear()}`
    }

    return text
}
