import { Box, Button, FormControl, Image, Input } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { MdUpload } from 'react-icons/md'

function ImageUploadBox() {
    const inputRef = useRef<HTMLInputElement>(null)

    const [fileLink, setFileLink] = useState<string>()

    const handleImageUploadChange: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
        if (evt.target.files) {
            const file = evt.target.files[0]
            const urlFileLink = URL.createObjectURL(file)

            setFileLink(urlFileLink)
        }
    }

    return (
        <Box
            bg="plejGrey.300"
            w="full"
            aspectRatio={3 / 2}
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            role="group"
        >
            <Image position="absolute" objectFit="contain" src={fileLink} />
            <FormControl
                display={fileLink ? 'none' : 'flex'}
                _groupHover={{
                    display: 'flex',
                }}
                justifyContent="center"
            >
                <Button
                    rightIcon={<MdUpload />}
                    onClick={() => inputRef.current!.click()}
                    maxW="80%"
                >
                    Upload
                </Button>

                <Input
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageUploadChange}
                    display="none"
                />
            </FormControl>
        </Box>
    )
}

export default ImageUploadBox
