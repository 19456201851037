import { Avatar, Heading, Stack, Text, VStack } from '@chakra-ui/react'

function Partner({
    name,
    bio,
    image,
    reverse = false,
}: {
    name: string
    bio: string
    image: string
    reverse?: boolean
}) {
    return (
        <Stack direction={reverse ? 'row-reverse' : 'row'} px="4" spacing="4" w="full">
            <Avatar name={name} src={image} size="xl" />
            <VStack alignItems="start">
                <Heading textTransform="capitalize" as="h2">
                    {name}
                </Heading>
                <Text>{bio}</Text>
            </VStack>
        </Stack>
    )
}

export default Partner
