import { Button, Heading, HStack, Icon, IconButton } from '@chakra-ui/react'
import RequiredCreateEventInput from '@v1_molecules/RequiredCreateEventInput/RequiredCreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import { getNames as getAllCountryNames, overwrite as overwriteCountry } from 'country-list'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'

overwriteCountry([
    {
        code: 'GB',
        name: 'United Kingdom',
    },
])

const countryNames = getAllCountryNames()

countryNames.sort()
countryNames.unshift(
    countryNames.splice(
        countryNames.findIndex((el) => el === 'United Kingdom'),
        1,
    )[0],
)

export type VenueDetailsFormValues = {
    addressLines: string[]
    city: string
    postcode: string
    country: string
}

export interface VenueDetailsFormProps extends CreateEventSectionProps {
    onChange: (details: VenueDetailsFormValues) => void
    onValidationChange: (isValid: boolean) => void
}

function VenueDetailsForm({ variant, onChange, onValidationChange }: VenueDetailsFormProps) {
    const [addressLines, setAddressLines] = useState([
        { value: '', isValid: false, isDeleted: false },
    ])
    const [city, setCity] = useState({ value: '', isValid: false })
    const [postcode, setPostcode] = useState({ value: '', isValid: false })
    const [country, setCountry] = useState({ value: '', isValid: false })

    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        onChange({
            addressLines: addressLines.map((addressLine) => addressLine.value),
            city: city.value,
            postcode: postcode.value,
            country: country.value,
        })

        const newIsValid =
            addressLines.every((line) => line.isValid) &&
            city.isValid &&
            postcode.isValid &&
            country.isValid

        if (newIsValid !== isValid) {
            onValidationChange(newIsValid)
            setIsValid(newIsValid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressLines, city, postcode, country])

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Venue details:
            </Heading>
            {addressLines.map((el, idx) => (
                <HStack w="full" spacing={4} display={el.isDeleted ? 'none' : 'flex'}>
                    <RequiredCreateEventInput
                        label={`Address line ${
                            idx -
                            addressLines.slice(0, idx).filter(({ isDeleted }) => isDeleted).length +
                            1
                        }:`}
                        errorMessage="Address lines must be filled out"
                        onChange={(v) => {
                            const newValue = _.clone(addressLines)
                            newValue[idx].value = v

                            setAddressLines(newValue)
                        }}
                        onValidationChange={(isAddressLineValid) => {
                            const newValue = _.clone(addressLines)
                            newValue[idx].isValid = isAddressLineValid

                            setAddressLines(newValue)
                        }}
                        parentFormVariant={variant}
                        type="text"
                    />
                    {addressLines.filter(({ isDeleted }) => !isDeleted).length > 1 ? (
                        <IconButton
                            alignSelf="end"
                            icon={<MdDelete />}
                            aria-label="delete address line"
                            minW={8}
                            w={8}
                            minH={8}
                            h={8}
                            p={0}
                            mb={2}
                            onClick={() => {
                                const newValue = _.clone(addressLines)
                                newValue[idx].isDeleted = true

                                setAddressLines(newValue)
                            }}
                        />
                    ) : null}
                </HStack>
            ))}
            <Button
                my="-4"
                variant="textButton"
                onClick={() =>
                    setAddressLines([
                        ...addressLines,
                        { value: '', isValid: false, isDeleted: false },
                    ])
                }
                fontSize="md"
            >
                <Icon as={MdAdd} boxSize={4} /> Add another address line
            </Button>
            <RequiredCreateEventInput
                label="City:"
                errorMessage="City is required"
                onChange={(v) => setCity({ value: v, isValid: city.isValid })}
                onValidationChange={(isCityValid) =>
                    setCity({ value: city.value, isValid: isCityValid })
                }
                parentFormVariant={variant}
                type="text"
            />
            <RequiredCreateEventInput
                label="Postcode:"
                errorMessage="Postcode is required"
                onChange={(v) => setPostcode({ value: v, isValid: postcode.isValid })}
                onValidationChange={(isPostcodeValid) =>
                    setPostcode({ value: postcode.value, isValid: isPostcodeValid })
                }
                parentFormVariant={variant}
                type="text"
            />
            <RequiredCreateEventInput
                label="Country:"
                errorMessage="Country is required"
                onChange={(v) => setCountry({ value: v, isValid: country.isValid })}
                onValidationChange={(isCountryValid) =>
                    setCountry({ value: country.value, isValid: isCountryValid })
                }
                parentFormVariant={variant}
                type="select"
                placeholder="Select a country"
                options={countryNames}
            />
        </CreateEventSection>
    )
}

export default VenueDetailsForm
