import { CurrencyV1, EventStatusV1, EventV1 } from '@commonwealthventures/poc-readstore-v1-models'

const MockEvent: EventV1 = {
    id: 'Event1',
    title: 'transformation spain retreat',
    requiredTotalOfPledgedAttendees: 100,
    currentAttendees: 75,
    date: {
        startDate: '2024-06-24T12:00:00.000Z',
        endDate: '2024-06-29T12:00:00.000Z',
    },
    pledgeEndDate: '2024-06-10T12:00:00.000Z',
    ticketSalesEndDate: '2024-06-12T12:00:00.000Z',
    capacity: 125,
    status: EventStatusV1.REQUIRES_FUNDING,
    summary:
        'Escape to the tranquility of Andalusian Mountains for a retreat experience like no other.',
    venue: {
        streetAddress: ['7 Lugar el Cañuelo'],
        postCode: '29710 Periana',
        city: 'Málaga',
        country: 'Spain',
    },
    images: {
        primary: { path: '/images/yoga-retreat-stock.png', alt: 'Yoga Retreat Stock' },
        confirmAndPay: [
            { path: '/images/yoga-retreat-stock.png', alt: 'Yoga Retreat Stock' },
            { path: '/images/yoga_thankyou.jpg', alt: 'Yoga Thank You' },
            { path: '/images/leaves.png', alt: 'Leaves' },
        ],
        thankYou: { path: '/images/yoga_thankyou.jpg', alt: 'Yoga Thank You' },
    },
    ticketOptions: [
        {
            name: 'Shared room',
            price: 800,
            currency: CurrencyV1.GBP,
            stripe: {
                priceId: 'stripe_price_1',
                productId: 'stripe_product_1',
            },
        },
        {
            name: 'Private room',
            price: 999,
            currency: CurrencyV1.GBP,
            stripe: {
                priceId: 'stripe_price_2',
                productId: 'stripe_product_2',
            },
        },
    ],
    organiser: {
        id: 'organiser123',
        name: 'Harry Potter',
        image: { path: '/images/yoga_thankyou.jpg', alt: 'Stupid Wizard' },
    },
    detailedOverview:
        "Escape to the tranquility of Andalusian Mountains for a retreat experience like no other. Each day will be filled with total self care: yoga overlooking the valley, nourishing vegan food, plenty of time walking taking in the fresh mountainous air and many more activities to nourish the mind, body & soul. We work with the best people to bring you what has been described on many occasions as a 'Life changing' experience. We hope you will join us!",
    whatsIncluded: [
        '5 nights accommodation in rustic Spanish Casitas',
        'Daily morning Seasonal inspired Hatha Yoga, tailored to suit all levels and individuals (time is taken to understand guests needs)',
        'Meditation & breath-work classes (twice a day)',
        'Yin Yoga, sound healing & essential oils',
        'Wholesome vegan meals prepared by local onsite specialist chef (2, 3 course meals per day, snacks & tea)',
        'Walking trips exploring the Andalusian mountains',
        'Gong bath with expert Christine Heckle',
        'Posture Workshop with Megan',
        'Personalised attention during classes and time with teacher during the retreat',
        'Mini posture workshop',
    ],
    whatsNotIncluded: [
        'Flights to Malaga',
        'Travel insurance',
        'Extra drinks & food outside of the menu',
        'Transfers from Malaga airport to retreat destination',
    ],
    providers: [
        {
            name: 'Megan',
            image: { path: 'images/yoga_thankyou.jpg', alt: 'Yoga Thankyou' },
            bio: 'I’m a 500 hour yoga and mindfulness teacher. I specialise in Yin and Seasonal Hatha Vinyasa yoga, and offer classes for both adults and children.',
        },
        {
            name: 'Robert',
            image: { path: 'images/yoga.jpg', alt: 'Yoga' },
            bio: 'I’m another member of the team using another image of Megan as its too hard to find the images in Figma that were actually used in the design but I guess this will do.',
        },
    ],
    additionalInformation: [
        {
            title: 'What you will learn',
            subSections: [
                {
                    sectionTitle: 'Yoga',
                    text: [
                        'Find out how to develop a daily yoga practice and incorporate it into your life,\nLearn how to adopt a mindful yoga practice to suit your body & mind,\nExplore the physical, mental and spiritual practices of yoga,\nDiscover how the breath can help manage stress, anxiety and calm the nervous system,\nLearn the basics of how to develop & maintain a meditation practice',
                    ],
                },
                {
                    sectionTitle: 'Life',
                    text: [
                        'How to dedicate time out for YOU,\nHow to live a more meaningful & fulfilling life,\nHow to develop a better relationship with yourself and others',
                    ],
                },
            ],
        },
    ],
}

export const FundedMockEvent: EventV1 = {
    id: 'Event1',
    title: 'transformation spain retreat',
    requiredTotalOfPledgedAttendees: 100,
    currentAttendees: 105,
    date: {
        startDate: '2024-06-24T12:00:00.000Z',
        endDate: '2024-06-29T12:00:00.000Z',
    },
    pledgeEndDate: '2024-06-10T12:00:00.000Z',
    ticketSalesEndDate: '2024-06-12T12:00:00.000Z',
    capacity: 125,
    status: EventStatusV1.REACHED_PLEDGE_GOAL,
    summary:
        'Escape to the tranquility of Andalusian Mountains for a retreat experience like no other.',
    venue: {
        streetAddress: ['7 Lugar el Cañuelo'],
        postCode: '29710 Periana',
        city: 'Málaga',
        country: 'Spain',
    },
    images: {
        primary: { path: '/images/yoga-retreat-stock.png', alt: 'Yoga Retreat Stock' },
        confirmAndPay: [
            { path: '/images/yoga-retreat-stock.png', alt: 'Yoga Retreat Stock' },
            { path: '/images/yoga_thankyou.jpg', alt: 'Yoga Thank You' },
            { path: '/images/leaves.png', alt: 'Leaves' },
        ],
        thankYou: { path: '/images/yoga_thankyou.jpg', alt: 'Yoga Thank You' },
    },
    ticketOptions: [
        {
            name: 'Shared room',
            price: 800,
            currency: CurrencyV1.GBP,
            stripe: {
                priceId: 'stripe_price_1',
                productId: 'stripe_product_1',
            },
        },
        {
            name: 'Private room',
            price: 999,
            currency: CurrencyV1.GBP,
            stripe: {
                priceId: 'stripe_price_2',
                productId: 'stripe_product_2',
            },
        },
    ],
    organiser: {
        id: 'organiser123',
        name: 'Harry Potter',
        image: { path: '/images/yoga_thankyou.jpg', alt: 'Stupid Wizard' },
    },
    detailedOverview:
        "Escape to the tranquility of Andalusian Mountains for a retreat experience like no other. Each day will be filled with total self care: yoga overlooking the valley, nourishing vegan food, plenty of time walking taking in the fresh mountainous air and many more activities to nourish the mind, body & soul. We work with the best people to bring you what has been described on many occasions as a 'Life changing' experience. We hope you will join us!",
    whatsIncluded: [
        '5 nights accommodation in rustic Spanish Casitas',
        'Daily morning Seasonal inspired Hatha Yoga, tailored to suit all levels and individuals (time is taken to understand guests needs)',
        'Meditation & breath-work classes (twice a day)',
        'Yin Yoga, sound healing & essential oils',
        'Wholesome vegan meals prepared by local onsite specialist chef (2, 3 course meals per day, snacks & tea)',
        'Walking trips exploring the Andalusian mountains',
        'Gong bath with expert Christine Heckle',
        'Posture Workshop with Megan',
        'Personalised attention during classes and time with teacher during the retreat',
        'Mini posture workshop',
    ],
    whatsNotIncluded: [
        'Flights to Malaga',
        'Travel insurance',
        'Extra drinks & food outside of the menu',
        'Transfers from Malaga airport to retreat destination',
    ],
    providers: [
        {
            name: 'Megan',
            image: { path: 'images/yoga_thankyou.jpg', alt: 'Yoga Thankyou' },
            bio: 'I’m a 500 hour yoga and mindfulness teacher. I specialise in Yin and Seasonal Hatha Vinyasa yoga, and offer classes for both adults and children.',
        },
        {
            name: 'Robert',
            image: { path: 'images/yoga.jpg', alt: 'Yoga' },
            bio: 'I’m another member of the team using another image of Megan as its too hard to find the images in Figma that were actually used in the design but I guess this will do.',
        },
    ],
    additionalInformation: [
        {
            title: 'What you will learn',
            subSections: [
                {
                    sectionTitle: 'Yoga',
                    text: [
                        'Find out how to develop a daily yoga practice and incorporate it into your life,\nLearn how to adopt a mindful yoga practice to suit your body & mind,\nExplore the physical, mental and spiritual practices of yoga,\nDiscover how the breath can help manage stress, anxiety and calm the nervous system,\nLearn the basics of how to develop & maintain a meditation practice',
                    ],
                },
                {
                    sectionTitle: 'Life',
                    text: [
                        'How to dedicate time out for YOU,\nHow to live a more meaningful & fulfilling life,\nHow to develop a better relationship with yourself and others',
                    ],
                },
            ],
        },
    ],
}

export default MockEvent
